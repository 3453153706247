import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Autosuggest from 'react-autosuggest'
import './EventSpace.css'

const EventSpace = ({
  externalValue, updateValue, spaces, placeholder,
}) => {
  const [suggestions, setSuggestions] = useState([])

  const getSuggestions = (value) => {
    const inputValue = value.toLowerCase()
    const inputLength = inputValue.length

    return inputLength === 0
      ? [] : spaces.filter((space) => space.name.toLowerCase().slice(0, inputLength) === inputValue)
  }

  const handleChange = (event, { newValue }) => {
    updateValue(newValue)
  }

  return (
    <>
      { isLoaded(spaces) && !isEmpty(spaces) && (
        <Row>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>
                Space
              </Form.Label>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={({ value }) => setSuggestions(getSuggestions(value))}
                onSuggestionsClearRequested={() => setSuggestions([])}
                getSuggestionValue={(space) => space}
                renderSuggestion={(space) => (
                  <div>
                    {space.name}
                  </div>
                )}
                inputProps={{
                  placeholder,
                  value: externalValue.name,
                  onChange: handleChange,
                }}
                theme={{
                  container: 'eventspace-autosuggest__container',
                  input: 'form-control',
                  suggestionsContainerOpen: 'form-control eventspace-autosuggest__suggestions-container--open',
                  suggestionsList: 'eventspace-autosuggest__suggestions-list',
                  suggestion: 'eventspace-autosuggest__suggestion',
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  spaces: state.firestore.ordered.spaces,
})
export default compose(
  firestoreConnect([{
    collection: 'spaces',
    orderBy: ['name', 'asc'],
    // where: ['status', '==', 'published'],
  }]),
  connect(mapStateToProps),
)(EventSpace)
