import { generatePerLocale } from '../../lib/locales'
import { ParseEditorContent, emptyEditorState } from '../../lib/utils'

export const ARTICLE_FORM_SET_FIELD_VALUE = 'ARTICLE_FORM_SET_FIELD_VALUE'
export const ARTICLE_FORM_SET_LOCALIZED_FIELD_VALUE = 'ARTICLE_FORM_SET_LOCALIZED_FIELD_VALUE'
export const ARTICLE_FORM_SET_CHECKING_VALIDATION = 'ARTICLE_FORM_SET_CHECKING_VALIDATION'
export const ARTICLE_FORM_SET_ERROR = 'ARTICLE_FORM_SET_ERROR'
export const ARTICLE_FORM_RESET_ERROR = 'ARTICLE_FORM_RESET_ERROR'
export const ARTICLE_FORM_SET_LOADING = 'ARTICLE_FORM_SET_LOADING'
export const ARTICLE_FORM_RESET = 'ARTICLE_FORM_RESET'
export const ARTICLE_FORM_LOAD_DATA = 'ARTICLE_FORM_LOAD_DATA'

// Post initial state
const postContentInitialState = {
  title: '',
  subtitle: '',
  mainContent: ParseEditorContent(emptyEditorState),
  seoTitle: '',
  seoDescription: '',
}

// This is our initial state
export const initialState = {
  formValues: {
    localizedContent: generatePerLocale(postContentInitialState),
    author: '',
    publishDate: null,
    imageCarousel: [],
    coverImage: {},
    status: 'draft',
    type: 'article',
    keywords: [],
    relatedTo: [],
    relatedExternal: [],
    languages: {
      en: true,
      es: true,
    },
    bookmarkCount: 0,
    bookmarks: [],
    slug: '',
  },
  isCheckingValidation: false,
  error: false,
  loading: false,
}

const localizedContentReducer = (state = generatePerLocale(postContentInitialState), action) => {
  switch (action.type) {
    case ARTICLE_FORM_SET_LOCALIZED_FIELD_VALUE:
      return {
        ...state,
        [action.locale]: {
          ...state[action.locale],
          [action.field]: action.data,
        },
      }
    default:
      return state
  }
}

const ArticleFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case ARTICLE_FORM_SET_FIELD_VALUE:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.field]: action.data,
        },
      }
    case ARTICLE_FORM_SET_LOCALIZED_FIELD_VALUE:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          localizedContent: localizedContentReducer(state.formValues.localizedContent, action),
        },
      }
    case ARTICLE_FORM_SET_CHECKING_VALIDATION:
      return {
        ...state,
        isCheckingValidation: true,
      }
    case ARTICLE_FORM_SET_ERROR: {
      return {
        ...state,
        error: action.data || false,
      }
    }
    case ARTICLE_FORM_RESET_ERROR: {
      return {
        ...state,
        error: false,
      }
    }
    case ARTICLE_FORM_SET_LOADING: {
      return {
        ...state,
        loading: action.data,
      }
    }
    case ARTICLE_FORM_RESET: {
      return initialState
    }
    default:
      return state
  }
}

export default ArticleFormReducer
