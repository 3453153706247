import React, { useReducer } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { convertToRaw } from 'draft-js'
import { getFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import RichEditor from '../Fields/RichEditor/RichEditor'
import Languages from '../Fields/Languages'
import PageFormReducer, { initialState } from './PageFormReducer'
import {
  setFieldValue, setLocalizedFieldValue, setCheckingValidation, setLoading, resetPageError,
} from './PageFormActions'
import { statusOptions } from '../../lib/constants'
import { ParseEditorContent } from '../../lib/utils'

const PageForm = ({ initialFormValues, pageId }) => {
  // useReducer is a new react way to use a custom local reducer instead of the usual
  // local state. In this case, PageFormReducer is our reducer.
  const history = useHistory()
  const [formState, dispatch] = useReducer(
    PageFormReducer, // Our Reducer
    initialState,
    () => { // This third parameter overrites our initialstaate
      if (pageId) {
        // NEED TO PARSE EDITOR CONTENT FROM INITIAL FORM VALUES
        return {
          ...initialState,
          formValues: {
            ...initialFormValues,
            localizedContent: {
              en: {
                ...initialFormValues.localizedContent.en,
                mainContent: ParseEditorContent(initialFormValues.localizedContent.en.mainContent),
              },
              es: {
                ...initialFormValues.localizedContent.es,
                mainContent: ParseEditorContent(initialFormValues.localizedContent.es.mainContent),
              },
            },
          },
        }
      }
      return initialState
    },
  )

  const {
    formValues,
    loading,
    isCheckingValidation,
  } = formState

  const {
    localizedContent,
    status,
    order,
    languages,
  } = formValues

  const { en, es } = localizedContent

  const submitPageForm = (data) => {
    const firebase = getFirebase()

    dispatch(resetPageError())
    dispatch(setLoading(true))

    let docRef = firebase.firestore().collection('pages').doc()

    // Check new or edit
    if (pageId) {
      docRef = firebase.firestore().collection('pages').doc(pageId)
    }

    docRef.set({
      createdDate: new Date().getTime(),
      ...data,
      updatedDate: new Date().getTime(),
      // REFACTOR
      localizedContent: {
        es: {
          ...data.localizedContent.es,
          mainContent: JSON.stringify(
            convertToRaw(data.localizedContent.es.mainContent.getCurrentContent()),
          ),
        },
        en: {
          ...data.localizedContent.en,
          mainContent: JSON.stringify(
            convertToRaw(data.localizedContent.en.mainContent.getCurrentContent()),
          ),
        },
      },
      // Convert editorState to Raw content
    })
      .then(() => {
        dispatch(setLoading(false))
        if (!pageId) {
          history.push(`/page/edit/${docRef.id}`)
        }
        // this is where we can trigger a notification or something
      })
      .catch((error) => {
        // Error ocurred
        dispatch(setLoading(false))
        // dispatch(setPageError(error))
      })
  }

  const onSubmitForm = (event) => {
    const form = event.currentTarget

    event.preventDefault()
    event.stopPropagation()

    if (form.checkValidity() === true) {
      submitPageForm(formValues)
    }

    // start checking validation on the form now we have initial values
    dispatch(setCheckingValidation())
  }

  const removePage = (selectedId) => {
    const firebase = getFirebase()

    if (selectedId) {
      // Confirm with user
      const confirmed = window.confirm('Are you sure you want to remove this page?')

      if (confirmed) {
        // Delete document
        firebase.firestore().collection('pages').doc(selectedId).delete()
          .then(() => {
            // Redirect to /pages
            history.push('/pages')
            // this is where we can trigger a notification or something
          })
          .catch((error) => {
            // Error ocurred
            dispatch(setLoading(false))
          })
      }
    }
  }

  return (
    <Container>
      <h2>{pageId ? 'Page' : 'New Page'}</h2>

      <Form
        noValidate
        validated={isCheckingValidation}
        onSubmit={(event) => onSubmitForm(event)}
      >
        <Row>
          <Col md={8}>
            <Row>
              <Col md={6}>
                <Form.Group controlId="es-title">
                  <Form.Label>Title Español</Form.Label>
                  <Form.Control type="text" value={es.title} onChange={(e) => dispatch(setLocalizedFieldValue('title', 'es', e.target.value))} disabled={loading} required />
                  <Form.Control.Feedback type="invalid">Please provide a valid title.</Form.Control.Feedback>
                </Form.Group>

              </Col>

              <Col md={6}>
                <Form.Group controlId="en-title">
                  <Form.Label>Title English</Form.Label>
                  <Form.Control type="text" value={en.title} onChange={(e) => dispatch(setLocalizedFieldValue('title', 'en', e.target.value))} disabled={loading} required />
                  <Form.Control.Feedback type="invalid">Please provide a valid title.</Form.Control.Feedback>
                </Form.Group>
              </Col>

            </Row>
            <Row>

              <Col md={6}>
                <Form.Group controlId="es-content">
                  <Form.Label>Content Español</Form.Label>
                  <RichEditor editorState={es.mainContent} onEditorStateChange={(editorState) => { dispatch(setLocalizedFieldValue('mainContent', 'es', editorState)) }} />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="en-content">
                  <Form.Label>Content English</Form.Label>
                  <RichEditor editorState={en.mainContent} onEditorStateChange={(editorState) => { dispatch(setLocalizedFieldValue('mainContent', 'en', editorState)) }} />
                </Form.Group>
              </Col>

            </Row>

          </Col>

          <Col>
            <Form.Group controlId="actions">
              <Form.Label>Actions</Form.Label>
              <Row className="justify-content-between">
                <Col>
                  <Button variant="primary" type="submit" disabled={loading} block>
                    { loading ? 'Loading…' : 'Save' }
                  </Button>
                </Col>
                { pageId
                  && (
                  <Col xs="auto">
                    <Button disabled={loading} variant="danger" onClick={() => removePage(pageId)} block>Remove</Button>
                  </Col>
                  )}
              </Row>
            </Form.Group>

            <Form.Group controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control as="select" onChange={(e) => dispatch(setFieldValue('status', e.target.value))} defaultValue={status} disabled={loading}>
                { Object.keys(statusOptions).map((key) => (
                  <option value={key} key={key}>{statusOptions[key]}</option>
                )) }
              </Form.Control>
            </Form.Group>

            <Languages value={languages} onChange={(value) => dispatch(setFieldValue('languages', value))} />

            <Form.Group controlId="order">
              <Form.Label>Order</Form.Label>
              <Form.Control type="text" value={order} onChange={(e) => dispatch(setFieldValue('order', e.target.value))} disabled={loading} />
            </Form.Group>

            <Form.Group controlId="es-seo-title">
              <Form.Label>SEO Title Español</Form.Label>
              <Form.Control value={es.seoTitle} onChange={(e) => dispatch(setLocalizedFieldValue('seoTitle', 'es', e.target.value))} />
            </Form.Group>

            <Form.Group controlId="en-seo-title">
              <Form.Label>SEO Title English</Form.Label>
              <Form.Control value={en.seoTitle} onChange={(e) => dispatch(setLocalizedFieldValue('seoTitle', 'en', e.target.value))} />
            </Form.Group>

            <Form.Group controlId="es-seo-description">
              <Form.Label>SEO Description Español</Form.Label>
              <Form.Control as="textarea" rows="3" value={es.seoDescription} onChange={(e) => dispatch(setLocalizedFieldValue('seoDescription', 'es', e.target.value))} />
            </Form.Group>

            <Form.Group controlId="en-seo-description">
              <Form.Label>SEO Description English</Form.Label>
              <Form.Control as="textarea" rows="3" value={en.seoDescription} onChange={(e) => dispatch(setLocalizedFieldValue('seoDescription', 'en', e.target.value))} />
            </Form.Group>

          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default PageForm
