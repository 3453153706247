import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { logout as logoutAction } from '../../actions/LoginActions'

const LogoutLink = ({ Element, logout, children }) => (
  <Element
    onClick={(e) => {
      e.preventDefault()
      logout()
    }}
  >
    {children}
  </Element>
)

LogoutLink.propTypes = {
  Element: PropTypes.object,
  logout: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
}

LogoutLink.defaultProps = {
  Element: 'a',
}

const mapDipatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutAction()),
})

export default compose(
  connect(
    null,
    mapDipatchToProps,
  ),
)(LogoutLink)
