import React from 'react'
import Form from 'react-bootstrap/Form'

const Languages = ({ value, onChange }) => {
  const handleOnChange = (e) => {
    const locale = e.target.getAttribute('locale')
    const { checked } = e.target
    onChange({
      ...value,
      [locale]: checked,
    })
  }
  return (
    <Form.Group>
      <Form.Label>Languages</Form.Label>
      <div>
        <Form.Check
          inline
          type="checkbox"
          label="Español"
          locale="es"
          checked={value ? value.es : false}
          onChange={handleOnChange}
        />
        <Form.Check
          inline
          type="checkbox"
          label="English"
          locale="en"
          checked={value ? value.en : false}
          onChange={handleOnChange}
        />
      </div>
    </Form.Group>
  )
}

export default Languages
