import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Pagination from '../Pagination'

const PageList = ({
  pages, totalPages, currentPage,
}) => (
  <Container>
    <Row>
      <Col>
        <h2>Pages</h2>
      </Col>
      <Col xs="auto">
        <Link to="/page/new">
          <Button>Add New</Button>
        </Link>
      </Col>
    </Row>

    { !isLoaded(pages)
        && <div>Loading...</div>}

    { isLoaded(pages) && isEmpty(pages)
        && <div>Pages List Is Empty</div>}

    { isLoaded(pages) && !isEmpty(pages) && (
      <>
        <Table>
          <thead>
            <tr>
              <th>
                Order
              </th>
              <th>
                Title
              </th>
              <th>
                Status
              </th>
              <th>
                Created
              </th>
            </tr>
          </thead>
          <tbody>
            { pages.map((page) => (
              <tr key={page.id}>
                <td>
                  {page.order}
                </td>
                <td>
                  <Link to={`/page/edit/${page.id}`}>{page.localizedContent.es.title}</Link>
                </td>
                <td>
                  {page.status}
                </td>
                <td>
                  {new Date(page.createdDate).toLocaleString('default', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination totalPages={totalPages} currentPage={currentPage} list="pages" />
      </>
    )}
  </Container>
)

PageList.propTypes = {
  pages: PropTypes.array,
}

PageList.defaultProps = {
  pages: [],
}

export default PageList
