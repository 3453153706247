import {
  MEDIA_FORM_LOAD_MEDIA,
  MEDIA_FORM_SET_FIELD_VALUE,
  MEDIA_FORM_SET_LOCALIZED_FIELD_VALUE,
  MEDIA_FORM_SET_CHECKING_VALIDATION,
  MEDIA_FORM_SET_ERROR,
  MEDIA_FORM_RESET_ERROR,
  MEDIA_FORM_SET_UPLOADING,
  MEDIA_FORM_SET_PREVIEW_URL,
  MEDIA_FORM_SET_LOADING,
  MEDIA_FORM_LOAD_DATA,
  MEDIA_FORM_RESET,
} from './MediaUploadFormReducer'

export const loadMedia = (data) => ({
  type: MEDIA_FORM_LOAD_MEDIA,
  data,
})

export const setFieldValue = (field, data) => ({
  type: MEDIA_FORM_SET_FIELD_VALUE,
  field,
  data,
})

export const setLocalizedFieldValue = (field, locale, data) => ({
  type: MEDIA_FORM_SET_LOCALIZED_FIELD_VALUE,
  field,
  locale,
  data,
})

export const setCheckingValidation = () => ({
  type: MEDIA_FORM_SET_CHECKING_VALIDATION,
})

export const setMediaError = (error = null) => ({
  type: MEDIA_FORM_SET_ERROR,
  data: error,
})

export const resetMediaError = () => ({
  type: MEDIA_FORM_RESET_ERROR,
})

export const setUploading = (uploading) => ({
  type: MEDIA_FORM_SET_UPLOADING,
  data: uploading,
})

export const setPreviewUrl = (url) => ({
  type: MEDIA_FORM_SET_PREVIEW_URL,
  data: url,
})

export const setLoading = (loading) => ({
  type: MEDIA_FORM_SET_LOADING,
  data: loading,
})

export const loadMediaData = (data) => ({
  type: MEDIA_FORM_LOAD_DATA,
  data,
})

export const resetMediaForm = () => ({
  type: MEDIA_FORM_RESET,
})
