import { generatePerLocale } from '../../lib/locales'
import { ParseEditorContent, emptyEditorState } from '../../lib/utils'

export const PAGE_FORM_SET_FIELD_VALUE = 'PAGE_FORM_SET_FIELD_VALUE'
export const PAGE_FORM_SET_LOCALIZED_FIELD_VALUE = 'PAGE_FORM_SET_LOCALIZED_FIELD_VALUE'
export const PAGE_FORM_SET_CHECKING_VALIDATION = 'PAGE_FORM_SET_CHECKING_VALIDATION'
export const PAGE_FORM_SET_ERROR = 'PAGE_FORM_SET_ERROR'
export const PAGE_FORM_RESET_ERROR = 'PAGE_FORM_RESET_ERROR'
export const PAGE_FORM_SET_LOADING = 'PAGE_FORM_SET_LOADING'
export const PAGE_FORM_RESET = 'PAGE_FORM_RESET'
export const PAGE_FORM_LOAD_DATA = 'PAGE_FORM_LOAD_DATA'

// Post initial state
const postContentInitialState = {
  title: '',
  mainContent: ParseEditorContent(emptyEditorState),
  seoTitle: '',
  seoDescription: '',
}

// This is our initial state
export const initialState = {
  formValues: {
    localizedContent: generatePerLocale(postContentInitialState),
    status: 'draft',
    order: 0,
    languages: {
      en: true,
      es: true,
    },
  },
  isCheckingValidation: false,
  error: false,
  loading: false,
}

const localizedContentReducer = (state = generatePerLocale(postContentInitialState), action) => {
  switch (action.type) {
    case PAGE_FORM_SET_LOCALIZED_FIELD_VALUE:
      return {
        ...state,
        [action.locale]: {
          ...state[action.locale],
          [action.field]: action.data,
        },
      }
    default:
      return state
  }
}

const PageFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAGE_FORM_SET_FIELD_VALUE:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.field]: action.data,
        },
      }
    case PAGE_FORM_SET_LOCALIZED_FIELD_VALUE:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          localizedContent: localizedContentReducer(state.formValues.localizedContent, action),
        },
      }
    case PAGE_FORM_SET_CHECKING_VALIDATION:
      return {
        ...state,
        isCheckingValidation: true,
      }
    case PAGE_FORM_SET_ERROR: {
      return {
        ...state,
        error: action.data || false,
      }
    }
    case PAGE_FORM_RESET_ERROR: {
      return {
        ...state,
        error: false,
      }
    }
    case PAGE_FORM_SET_LOADING: {
      return {
        ...state,
        loading: action.data,
      }
    }
    case PAGE_FORM_RESET: {
      return initialState
    }
    default:
      return state
  }
}

export default PageFormReducer
