import React from 'react'
import { RichUtils } from 'draft-js'
import StyleButton from './StyleButton'
import LinkButton from './LinkButton'
import VideoButton from './VideoButton'

const InlineStyleControls = ({
  editorState,
  onToggle,
  onLinkToggle,
  urlInputActive,
  onVideoToggle,
  videoInputActive,
}) => {
  const INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ]
  const currentStyle = editorState.getCurrentInlineStyle()
  const linkActive = RichUtils.currentBlockContainsLink(editorState)

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
      <LinkButton
        onToggle={onLinkToggle}
        editorState={editorState}
        urlInputActive={urlInputActive}
        active={linkActive}
      />
      <VideoButton
        onToggle={onVideoToggle}
        editorState={editorState}
        active={videoInputActive}
      />
    </div>
  )
}

export default InlineStyleControls
