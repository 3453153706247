import React, { useReducer } from 'react'
import axios from 'axios'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import { getFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import UserFormReducer, { initialState } from './UserFormReducer'
import {
  setFieldValue, setCheckingValidation, setLoading, resetUserError,
} from './UserFormActions'
import { userRoleOptions, CloudFunctionsApi } from '../../lib/constants'

const UserForm = ({
  initialFormValues, userId, authUID,
}) => {
  const history = useHistory()
  // useReducer is a new react way to use a custom local reducer instead of the usual
  // local state. In this case, UserFormReducer is our reducer.
  const [formState, dispatch] = useReducer(
    UserFormReducer, // Our Reducer
    initialState,
    () => { // This third parameter overrites our initialstaate
      if (userId) {
        // NEED TO PARSE EDITOR CONTENT FROM INITIAL FORM VALUES
        return {
          ...initialState,
          formValues: initialFormValues,
        }
      }
      return initialState
    },
  )

  const {
    formValues,
    loading,
    isCheckingValidation,
  } = formState

  const {
    firstName,
    lastName,
    email,
    active,
    role,
    password,
  } = formValues

  const submitUserForm = (data) => {
    const firebase = getFirebase()

    dispatch(resetUserError())
    dispatch(setLoading(true))

    const cloudFunction = userId ? `${CloudFunctionsApi}updateUser` : `${CloudFunctionsApi}createUser`

    const displayName = `${firstName} ${lastName}`

    firebase.auth().currentUser.getIdToken(true /* Force refresh */)
      .then((idToken) => {
        // Call create user function
        axios.get(cloudFunction, {
          params: {
            uid: userId || null,
            email: data.email,
            password: data.password,
            displayName,
          },
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: idToken,
          },
          mode: 'no-cors',
        }).then((response) => {
          const uid = response.data
          const { password: uselessPassword, ...cleanFormValues } = data

          // Use set() to push to user profile with UID from user
          firebase.firestore().collection('users').doc(uid).set({
            createdDate: new Date().getTime(),
            ...cleanFormValues,
            updatedDate: new Date().getTime(),
          })
            .then(() => {
              dispatch(setLoading(false))
              if (!userId) {
                history.push(`/user/edit/${uid}`)
              }
            })
        })
      }).catch((error) => {
        dispatch(setLoading(false))
        console.log(error)
      })
  }

  const onSubmitForm = (event) => {
    const form = event.currentTarget

    event.preventDefault()
    event.stopPropagation()

    if (form.checkValidity() === true) {
      submitUserForm(formValues)
    }

    // start checking validation on the form now we have initial values
    dispatch(setCheckingValidation())
  }

  const removeUser = (uid) => {
    const firebase = getFirebase()

    dispatch(setLoading(true))

    const confirmed = window.confirm('Are you sure you want to remove this user?')

    if (confirmed) {
      const deleteUserFunction = `${CloudFunctionsApi}deleteUser`
      // const deleteUserFunction = LocalFunctionsApi + 'deleteUser';

      firebase.auth().currentUser.getIdToken(true /* Force refresh */)
        .then((idToken) => {
          // Call create user function
          axios.get(deleteUserFunction, {
            params: {
              uid,
            },
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: idToken,
            },
            mode: 'no-cors',
          }).then((response) => {
            firebase.firestore().collection('users').doc(uid).delete()
          }).then(() => {
            dispatch(setLoading(false))
            // Redirect to /users
            history.push('/users')
            // this is where we can trigger a notification or something
          }).catch((error) => {
            dispatch(setLoading(false))
            console.log(error)
          })
        }).catch((error) => {
          dispatch(setLoading(false))
          console.log(error)
        })
    } else {
      dispatch(setLoading(false))
    }
  }

  const generatePassword = () => {
    let passString = ''
    const charLib = 'abcdefghijklmnopqrstuvwxyz1234567890'
    const passLength = 10

    for (let i = 0; i < passLength; i += 1) {
      passString += charLib[Math.floor(Math.random() * charLib.length)]
    }

    dispatch(setFieldValue('password', passString))
  }

  return (
    <Container>
      <h2>{userId ? 'User' : 'New User'}</h2>

      <Form
        noValidate
        validated={isCheckingValidation}
        onSubmit={(event) => onSubmitForm(event)}
      >
        <Row>
          <Col md={8}>
            <Row>

              <Col md={12}>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" value={email} onChange={(e) => dispatch(setFieldValue('email', e.target.value))} disabled={loading} required />
                  <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
                </Form.Group>
              </Col>

            </Row>
            <Row>

              <Col md={6}>
                <Form.Group controlId="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" value={firstName} onChange={(e) => dispatch(setFieldValue('firstName', e.target.value))} disabled={loading} />
                </Form.Group>

              </Col>
              <Col md={6}>
                <Form.Group controlId="lastName">
                  <Form.Label>Last Name(s)</Form.Label>
                  <Form.Control type="text" value={lastName} onChange={(e) => dispatch(setFieldValue('lastName', e.target.value))} disabled={loading} />
                </Form.Group>

              </Col>

            </Row>
            <Row>

              <Col md={12} className="align-self-end">
                <Form.Label>Password</Form.Label>
                <InputGroup className="mb-3">

                  <Form.Control type="text" value={password} onChange={(e) => dispatch(setFieldValue('password', e.target.value))} disabled={loading} minLength="6" />
                  <InputGroup.Append>
                    <Button
                      onClick={() => generatePassword()}
                    >
                      Generate
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                <div><span>Minimum 6 characters</span></div>
              </Col>

            </Row>
          </Col>

          <Col>
            <Form.Group controlId="actions">
              <Form.Label>Actions</Form.Label>
              <Row className="justify-content-between">
                <Col>
                  <Button variant="primary" type="submit" disabled={loading} block>
                    { loading ? 'Loading…' : 'Save' }
                  </Button>
                </Col>
                { userId
                  && (
                  <Col xs="auto">
                    <Button disabled={loading} variant="danger" onClick={() => removeUser(userId)} block>Remove</Button>
                  </Col>
                  )}
              </Row>
            </Form.Group>

            <Form.Group controlId="active">
              <Form.Label>Active</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  const boolValue = e.target.value === 'true'
                  dispatch(setFieldValue('active', boolValue))
                }}
                defaultValue={active}
                disabled={loading || (userId === authUID)}
              >
                <option>true</option>
                <option>false</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="role">
              <Form.Label>Role</Form.Label>
              <Form.Control as="select" onChange={(e) => dispatch(setFieldValue('role', e.target.value))} defaultValue={role} disabled={loading || (userId === authUID)}>
                { Object.keys(userRoleOptions).map((key) => (
                  <option value={key} key={key}>{userRoleOptions[key]}</option>
                )) }
              </Form.Control>
            </Form.Group>

          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default UserForm
