import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import UserList from '../components/User/UserList'
import { listContainerPaginateProps } from '../lib/utils'

// In this case, we dont do isLoaded/isEmpty in the container
// we leverage that to <Users /> because theres things to show
// even while loading
const UserListContainer = ({ users, totalPages, currentPage }) => (
  <UserList
    users={users}
    totalPages={totalPages}
    currentPage={currentPage}
  />
)

UserListContainer.propTypes = {
  users: PropTypes.array,
}

UserListContainer.defaultProps = {
  users: [],
}

const mapStateToProps = (state, props) => {
  const { docs, totalPages, currentPage } = listContainerPaginateProps(
    state, props, state.firestore.ordered.users,
  )
  return {
    users: docs,
    totalPages,
    currentPage,
  }
}

export default compose(
  firestoreConnect([{
    collection: 'users',
    orderBy: ['createdDate', 'desc'],
  }]),
  connect(mapStateToProps),
)(UserListContainer)
