import {
  SET_MODAL_PAGE,
} from '../reducers/PaginationReducer'

const setModalPage = (data) => ({
  type: SET_MODAL_PAGE,
  data,
})

export default setModalPage
