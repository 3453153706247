import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Pagination from '../Pagination'

const SpaceList = ({
  spaces, totalPages, currentPage,
}) => (
  <Container>
    <Row>
      <Col>
        <h2>Spaces</h2>
      </Col>
      <Col xs="auto">
        <Link to="/space/new">
          <Button>Add New</Button>
        </Link>
      </Col>
    </Row>

    { !isLoaded(spaces)
        && <div>Loading...</div>}

    { isLoaded(spaces) && isEmpty(spaces)
        && <div>Spaces List Is Empty</div>}

    { isLoaded(spaces) && !isEmpty(spaces) && (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              Name
            </th>
            <th>
              City
            </th>
            <th>
              Colonia
            </th>
            <th>
              Type
            </th>
            <th>
              Created
            </th>
          </tr>
        </thead>
        <tbody>
          { spaces.map((space) => (
            <tr key={space.id}>
              <td>
                <Link to={`/space/edit/${space.id}`}>{space.name}</Link>
              </td>
              <td>
                {space.city}
              </td>
              <td>
                {space.colonia}
              </td>
              <td>
                {space.type}
              </td>
              <td>
                {new Date(space.createdDate).toLocaleString('default', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination totalPages={totalPages} currentPage={currentPage} list="spaces" />
    </>
    )}
  </Container>
)

SpaceList.propTypes = {
  spaces: PropTypes.array,
}

SpaceList.defaultProps = {
  spaces: [],
}

export default SpaceList
