import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { transformEntryValues } from '../lib/utils'
import SpaceForm from '../components/Space/SpaceForm'

const SpaceContainer = ({ spaceId, space, related }) => {
  if (!isLoaded(space)) {
    return <div>Loading...</div>
  }

  if (isEmpty(space)) {
    return '?'
  }

  // transform data from Firestore for Form components
  const spaceTransformed = transformEntryValues(space)

  return (
    <SpaceForm
      initialFormValues={spaceTransformed}
      spaceId={spaceId}
      related={related}
    />
  )
}

SpaceContainer.propTypes = {
  spaceId: PropTypes.string.isRequired,
  space: PropTypes.object,
  related: PropTypes.object,
}

SpaceContainer.defaultProps = {
  space: {},
  related: {},
}

const firebaseQueries = (props) => {
  const { spaceId } = props.match.params

  return [
    {
      collection: 'spaces',
      doc: spaceId,
      storeAs: `currentSpace-${spaceId}`,
    },
    {
      collection: 'events',
      where: ['relatedTo', 'array-contains', spaceId],
      orderBy: ['createdDate', 'desc'],
      storeAs: `relatedTo-${spaceId}-events`,
    },
    {
      collection: 'spaces',
      where: ['relatedTo', 'array-contains', spaceId],
      orderBy: ['createdDate', 'desc'],
      storeAs: `relatedTo-${spaceId}-spaces`,
    },
    {
      collection: 'articles',
      where: ['relatedTo', 'array-contains', spaceId],
      orderBy: ['createdDate', 'desc'],
      storeAs: `relatedTo-${spaceId}-articles`,
    },
  ]
}

const mapStateToProps = (state, props) => {
  // Here we are not mapping from state but form other props
  const { spaceId } = props.match.params

  return {
    spaceId,
    space: state.firestore.data[`currentSpace-${spaceId}`],
    related: {
      spaces: state.firestore.ordered[`relatedTo-${spaceId}-spaces`],
      events: state.firestore.ordered[`relatedTo-${spaceId}-events`],
      articles: state.firestore.ordered[`relatedTo-${spaceId}-articles`],
    },
  }
}

export default compose(
  firestoreConnect(firebaseQueries),
  connect(mapStateToProps),
)(SpaceContainer)
