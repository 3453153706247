import React, { useState, useEffect } from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import MediaLibraryModal from '../Media/MediaLibraryModal'

const MediaCarousel = ({
  media, mediaPage, totalPages, currentPage, loading, value, handleOnChange,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [carouselMedia, setCarouselMedia] = useState(value)

  useEffect(() => {
    setCarouselMedia(value)
  }, [value])

  const removeItem = (removeIndex) => {
    const updatedValue = carouselMedia.filter((_media, index) => index !== removeIndex)
    handleOnChange(updatedValue)
  }

  return (
    <>
      <Form.Group>
        <Form.Label>Media Carousel</Form.Label>

        {carouselMedia && (
          <>
            <Row>
              {
                carouselMedia.map((img, index) => (
                  <Col key={img.id} md="3" className="carousel-media-item form-group">
                    <Card>
                      <Card.Img variant="top" src={img.mediaUrl} />
                      <Card.Body>
                        <Button
                          onClick={() => removeItem(index)}
                          variant="warning"
                          disabled={loading}
                        >
                          Remove
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              }
            </Row>

            <Row>
              <Col>
                <Button
                  onClick={() => setShowModal(true)}
                  disabled={loading}
                >
                  Add Image
                </Button>
              </Col>
            </Row>
          </>
        )}

      </Form.Group>

      <MediaLibraryModal
        showModal={showModal}
        setShowModal={setShowModal}
        media={media}
        mediaPage={mediaPage}
        totalPages={totalPages}
        currentPage={currentPage}
        handleOnChange={(mediaItem) => {
          handleOnChange([
            ...carouselMedia,
            mediaItem,
          ])
        }}
      />

    </>
  )
}

export default MediaCarousel
