import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Pagination from '../Pagination'

const HighlightList = ({
  highlighted, featured, totalPages, currentPage,
}) => (
  <Container>
    <Row>
      <Col>
        <h2>Featured</h2>
      </Col>
    </Row>

    { !isLoaded(featured)
        && <div>Loading...</div>}

    { isLoaded(featured) && isEmpty(featured)
        && <div>Featured List Is Empty</div>}

    { isLoaded(featured) && !isEmpty(featured) && (
    <Table>
      <thead>
        <tr>
          <th>
            Order
          </th>
          <th>
            Name
          </th>
          <th>
            City
          </th>
          <th>
            Space
          </th>
          <th>
            Type
          </th>
          <th>
            Opening
          </th>
        </tr>
      </thead>
      <tbody>
        { featured.map((event) => (
          <tr key={event.id}>
            <td>
              {event.featuredOrder}
            </td>
            <td>
              <Link to={`/event/edit/${event.id}`}>{event.localizedContent.es.name}</Link>
            </td>
            <td>
              {event.city}
            </td>
            <td>
              {event.space.name}
            </td>
            <td>
              <span>{event.type}</span>
            </td>
            <td>
              {event.openingStart
                      && (
                      <span>
                        {new Date(event.openingStart.seconds * 1000).toLocaleString('default', {
                          day: '2-digit',
                          month: '2-digit',
                          year: '2-digit',
                        })}
                      </span>
                      )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    )}

    <Row>
      <Col>
        <h2>Highlights</h2>
      </Col>
    </Row>

    { !isLoaded(highlighted)
        && <div>Loading...</div>}

    { isLoaded(highlighted) && isEmpty(highlighted)
        && <div>Highlights List Is Empty</div>}

    { isLoaded(highlighted) && !isEmpty(highlighted) && (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              Name
            </th>
            <th>
              City
            </th>
            <th>
              Space
            </th>
            <th>
              Type
            </th>
            <th>
              Opening
            </th>
          </tr>
        </thead>
        <tbody>
          { highlighted.map((event) => (
            <tr key={event.id}>
              <td>
                <Link to={`/event/edit/${event.id}`}>{event.localizedContent.es.name}</Link>
              </td>
              <td>
                <span>{event.city}</span>
              </td>
              <td>
                <span>{event.space.name}</span>
              </td>
              <td>
                <span>{event.type}</span>
              </td>
              <td>
                {event.openingStart
                        && (
                        <span>
                          {new Date(event.openingStart.seconds * 1000).toLocaleString('default', {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                          })}
                        </span>
                        )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination totalPages={totalPages} currentPage={currentPage} list="highlights" />
    </>
    )}
  </Container>
)

HighlightList.propTypes = {
  highlighted: PropTypes.array,
  featured: PropTypes.array,
}

HighlightList.defaultProps = {
  highlighted: [],
  featured: [],
}

export default HighlightList
