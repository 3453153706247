import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { paginateModal } from '../lib/utils'

const MediaPickerContainer = ({
  media,
  mediaPage,
  currentPage,
  totalPages,
  PickerComponent,
  value,
  handleOnChange,
  modifier,
  editorState,
  label,
  clearable,
}) => (
  <PickerComponent
    clearable={clearable}
    media={media}
    mediaPage={mediaPage}
    currentPage={currentPage}
    totalPages={totalPages}
    value={value}
    handleOnChange={handleOnChange}
    modifier={modifier}
    editorState={editorState}
    label={label}
  />
)

MediaPickerContainer.propTypes = {
  media: PropTypes.array,
}

MediaPickerContainer.defaultProps = {
  media: [],
}

const mapStateToProps = (state, props) => {
  const { docs, totalPages, currentPage } = paginateModal(
    state, props, state.firestore.ordered.media, state.pagination.modalPage,
  )
  return {
    media: state.firestore.ordered.media,
    mediaPage: docs,
    totalPages,
    currentPage,
  }
}

export default compose(
  firestoreConnect([{
    collection: 'media',
    orderBy: ['createdDate', 'desc'],
  }]),
  connect(mapStateToProps),
)(MediaPickerContainer)
