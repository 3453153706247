import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { transformEntryValues } from '../lib/utils'
import SettingsClientForm from '../components/Settings/SettingsClientForm'
import SettingsWebForm from '../components/Settings/SettingsWebForm'

const SettingsContainer = ({ settingsId, settings }) => {
  if (!isLoaded(settings)) {
    return <div>Loading...</div>
  }

  if (isEmpty(settings)) {
    return '?'
  }

  // transform data from Firestore for Form components
  const settingsTransformed = transformEntryValues(settings)

  if (settingsId === 'client') {
    return (
      <SettingsClientForm
        initialFormValues={settingsTransformed}
        settingsId={settingsId}
      />
    )
  }
  if (settingsId === 'web') {
    return (
      <SettingsWebForm
        initialFormValues={settingsTransformed}
        settingsId={settingsId}
      />
    )
  }

  return null
}

SettingsContainer.propTypes = {
  settingsId: PropTypes.string.isRequired,
  settings: PropTypes.object,
}

SettingsContainer.defaultProps = {
  settings: {},
}

const firebaseQueries = (props) => {
  const { settingsId } = props.match.params

  return [{
    collection: 'settings',
    doc: settingsId,
    storeAs: `settings-${settingsId}`,
  }]
}

const mapStateToProps = (state, props) => {
  // Here we are not mapping from state but form other props
  const { settingsId } = props.match.params

  return {
    settingsId,
    settings: state.firestore.data[`settings-${settingsId}`],
  }
}

export default compose(
  firestoreConnect(firebaseQueries),
  connect(mapStateToProps),
)(SettingsContainer)
