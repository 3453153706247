import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useFirestore } from 'react-redux-firebase'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Input from '../Input'
import { activeOptions } from '../../lib/constants'

const CityForm = ({ initialFormValues, cityId }) => {
  const firestore = useFirestore()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const {
    register, handleSubmit, formState: { errors, touchedFields },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialFormValues,
    criteriaMode: 'firstError',
    shouldFocusError: true,
  })

  const validateCode = async (value) => {
    if (cityId) return true

    const docRef = firestore.collection('cities').doc(value)
    const doc = await docRef.get()
    return !doc.exists || 'This code is already taken'
  }

  const isLatitude = (value) => (value && value >= -90 && value <= 90) || 'Please enter a valid latitude'

  const isLongitude = (value) => (value && value >= -180 && value <= 180) || 'Please enter a valid longitude'

  const onSubmit = async (data) => {
    setLoading(true)
    const docRef = firestore.collection('cities').doc(data.code)
    const doc = await docRef.get()

    if (!doc.exists || cityId) {
      try {
        const ref = await docRef.set({
          createdDate: new Date().getTime(),
          updatedDate: new Date().getTime(),
          ...data,
        })
        setLoading(false)
        history.push('/cities')
      } catch (e) {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }

  return (
    <Container>
      <h2>{cityId ? 'City' : 'New City'}</h2>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={8}>
            <Row>
              <Col md={6}>
                <Input
                  id="es-name"
                  label="Name Español"
                  errors={errors?.localizedContent?.es?.name?.message}
                  type="text"
                  isValid={touchedFields?.localizedContent?.es?.name
                      && !errors?.localizedContent?.es?.name}
                  isInvalid={!!errors?.localizedContent?.es?.name}
                  register={register('localizedContent.es.name', {
                    required: 'Can\'t be blank',
                  })}
                  disabled={loading}
                />
              </Col>

              <Col md={6}>
                <Input
                  id="en-name"
                  label="Name English"
                  errors={errors?.localizedContent?.en?.name?.message}
                  type="text"
                  isValid={touchedFields?.localizedContent?.en?.name
                      && !errors?.localizedContent?.en?.name}
                  isInvalid={!!errors?.localizedContent?.en?.name}
                  register={register('localizedContent.en.name', {
                    required: 'Can\'t be blank',
                  })}
                  disabled={loading}
                />
              </Col>

            </Row>
            <Row>
              <Col md={6}>
                <Input
                  id="lat"
                  label="Latitude"
                  errors={errors?.location?.lat?.message}
                  type="text"
                  isValid={touchedFields?.location?.lat
                      && !errors?.location?.lat}
                  isInvalid={!!errors?.location?.lat}
                  register={register('location.lat', {
                    required: 'Can\'t be blank',
                    validate: {
                      valid: isLatitude,
                    },
                    setValueAs: (v) => parseFloat(v),
                    pattern: {
                      value: /\d+\.?\d?/,
                      message: 'Enter a decimal',
                    },
                  })}
                  disabled={loading}
                />
              </Col>

              <Col md={6}>
                <Input
                  id="lon"
                  label="Longitude"
                  errors={errors?.location?.lon?.message}
                  type="text"
                  isValid={touchedFields?.location?.lon
                      && !errors?.location?.lon}
                  isInvalid={!!errors?.location?.lon}
                  register={register('location.lon', {
                    required: 'Can\'t be blank',
                    validate: {
                      valid: isLongitude,
                    },
                    setValueAs: (v) => parseFloat(v),
                    pattern: {
                      value: /\d+\.?\d?/,
                      message: 'Enter a decimal',
                    },
                  })}
                  disabled={loading}
                />
              </Col>

            </Row>
          </Col>

          <Col>
            <Form.Group controlId="actions">
              <Form.Label>Actions</Form.Label>
              <Row className="justify-content-between">
                <Col>
                  <Button variant="primary" type="submit" disabled={loading} block>
                    { loading ? 'Loading…' : 'Save' }
                  </Button>
                </Col>
              </Row>
            </Form.Group>

            <Input
              id="status"
              label="Status"
              errors={errors?.status?.message}
              as="select"
              isValid={touchedFields?.status && !errors?.status}
              isInvalid={!!errors?.status}
              register={register('status', {
                required: 'Can\'t be blank',
              })}
              disabled={loading}
            >
              { Object.keys(activeOptions).map((key) => (
                <option value={key} key={key}>{activeOptions[key]}</option>
              )) }
            </Input>

            {
              !cityId ? (
                <Input
                  id="code"
                  label="Code"
                  errors={errors?.code?.message}
                  type="text"
                  register={register('code', {
                    minLength: {
                      value: 3,
                      message: 'Enter minimum 3 characters',
                    },
                    maxLength: {
                      value: 6,
                      message: 'Enter minimum 6 characters',
                    },
                    required: 'Can\'t be blank',
                    validate: {
                      unique: validateCode,
                    },
                    setValueAs: (v) => v.toUpperCase(),
                    pattern: {
                      value: /[A-Za-z]{3,6}/,
                      message: 'Enter a 4-6 letter code',
                    },
                  })}
                  disabled={loading}
                  isValid={touchedFields?.code && !errors?.code}
                  isInvalid={!!errors?.code}
                />
              ) : null
}
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default CityForm
