/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'
import axios from 'axios'
import Base64 from 'Base64'
import { getFirebase } from 'react-redux-firebase'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import { ElasticEndpoint } from '../../lib/constants'
import { getMediaThumb } from '../../lib/images'

window.btoa = Base64.btoa

const RelatedInternalContent = ({
  related = { spaces: [], articles: [], events: [] },
  collection,
  loading,
  docId,
}) => {
  const [query, setQuery] = useState('')
  const [searchHits, setSearchHits] = useState([])
  const [searching, setSearching] = useState(false)
  const [relating, setRelating] = useState(false)
  const [error, setError] = useState(false)

  const search = (searchQuery) => {
    setSearching(true)

    axios.get(`${ElasticEndpoint}/_all/_search?q=${searchQuery}`)
      .then((response) => {
        setSearching(false)
        setSearchHits(response.data.hits.hits)
      })
      .catch((err) => {
        setSearching(false)
        console.error(err)
      })
  }

  const relateItem = (itemCollection, itemId) => {
    setRelating(true)

    const firebase = getFirebase()
    const thisDocRef = firebase.firestore().collection(collection).doc(docId)
    const relatedDocRef = firebase.firestore().collection(itemCollection).doc(itemId)

    thisDocRef.update({
      relatedTo: firebase.firestore.FieldValue.arrayUnion(itemId),
    })
      .then(() => relatedDocRef.update({
        relatedTo: firebase.firestore.FieldValue.arrayUnion(docId),
      }))
      .then(() => {
        setRelating(false)
      })
      .catch((err) => {
        setRelating(false)
        // FIXME: Circular
        // eslint-disable-next-line no-use-before-define
        removeItem(collection, itemId)
        console.error(err)
      })
  }

  const removeItem = (itemCollection, itemId) => {
    setRelating(true)

    const firebase = getFirebase()
    const thisDocRef = firebase.firestore().collection(collection).doc(docId)
    const relatedDocRef = firebase.firestore().collection(itemCollection).doc(itemId)

    thisDocRef.update({
      relatedTo: firebase.firestore.FieldValue.arrayRemove(itemId),
    })
      .then(() => relatedDocRef.update({
        relatedTo: firebase.firestore.FieldValue.arrayRemove(docId),
      }))
      .then(() => {
        setRelating(false)
      })
      .catch((err) => {
        setRelating(false)
        relateItem(itemCollection, itemId)
        console.error(err)
      })
  }

  return (
    <Row>

      <Col md={12} className="align-self-end">
        <Form.Label>Related Internal</Form.Label>
        <InputGroup className="mb-3">

          <Form.Control
            type="text"
            value={query}
            style={error ? { color: 'red' } : {}}
            onChange={(e) => {
              setError(false)
              setQuery(e.target.value)
            }}
            disabled={loading || searching || relating || !docId}
            placeholder={!docId ? 'Please save before relating' : null}
          />
          <InputGroup.Append>
            <Button
              onClick={() => search(query)}
              disabled={loading || searching || relating || query.length === 0}
            >
              {searching ? 'Searching' : 'Search'}
            </Button>
          </InputGroup.Append>
          <InputGroup.Append>
            <Button
              onClick={() => {
                setQuery('')
                setSearchHits([])
              }}
              disabled={loading || searching || relating || query.length === 0}
              variant="secondary"
            >
              Clear
            </Button>
          </InputGroup.Append>

        </InputGroup>
      </Col>

      {searchHits.length > 0
        && (
        <Col md={12}>
          {
            searchHits.map((item) => {
              if (item._id === docId) {
                return null
              }

              const content = item._source
              const itemCollection = item._index
              let { name } = content
              if (itemCollection === 'events') {
                name = content.localizedContent.es.name
              } else if (itemCollection === 'articles') {
                name = content.localizedContent.es.title
              }

              return (
                <Row key={item.id} className="related-item">
                  <Col xs="3">
                    {content.coverImage
                      && <img src={getMediaThumb(content.coverImage.mediaUrl)} alt="Cover" />}
                  </Col>
                  <Col>
                    <div>{name}</div>
                    <div>{itemCollection}</div>
                  </Col>
                  <Col xs="auto">
                    <Button
                      onClick={() => relateItem(
                        itemCollection, item._id,
                      )}
                      disabled={loading || searching || relating}
                    >
                      Relate
                    </Button>
                  </Col>
                </Row>
              )
            })
          }
        </Col>
        )}

      {related.spaces && related.events && related.articles
        && (
        <Col md={12}>
          {
            related.spaces.map((item) => (
              <Row key={item.id} className="related-item">
                <Col xs="3">
                  {item.coverImage
                      && <img src={getMediaThumb(item.coverImage.mediaUrl)} alt="Cover" />}
                </Col>
                <Col>
                  <div>{item.name}</div>
                  <div>Space</div>
                </Col>
                <Col xs="auto">
                  <Button onClick={() => removeItem('spaces', item.id)} variant="warning" disabled={loading || searching || relating}>Remove</Button>
                </Col>
              </Row>
            ))
          }
          {
            related.events.map((item) => (
              <Row key={item.id} className="related-item">
                <Col xs="3">
                  {item.coverImage
                      && <img src={getMediaThumb(item.coverImage.mediaUrl)} alt="Cover" />}
                </Col>
                <Col>
                  <div>{item.localizedContent.es.name}</div>
                  <div>Event</div>
                </Col>
                <Col xs="auto">
                  <Button onClick={() => removeItem('events', item.id)} variant="warning" disabled={loading || searching || relating}>Remove</Button>
                </Col>
              </Row>
            ))
          }
          {
            related.articles.map((item) => (
              <Row key={item.id} className="related-item">
                <Col xs="3">
                  {item.coverImage
                      && <img src={getMediaThumb(item.coverImage.mediaUrl)} alt="Cover" />}
                </Col>
                <Col>
                  <div>{item.localizedContent.es.title}</div>
                  <div>Article</div>
                </Col>
                <Col xs="auto">
                  <Button onClick={() => removeItem('articles', item.id)} variant="warning" disabled={loading || searching || relating}>Remove</Button>
                </Col>
              </Row>
            ))
          }
        </Col>
        )}
    </Row>
  )
}

export default RelatedInternalContent
