import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import ArticleList from '../components/Article/ArticleList'
import { listContainerPaginateProps } from '../lib/utils'

// In this case, we dont do isLoaded/isEmpty in the container
// we leverage that to <Articles /> because theres things to show
// even while loading
const ArticleListContainer = ({ articles, totalPages, currentPage }) => (
  <ArticleList
    articles={articles}
    totalPages={totalPages}
    currentPage={currentPage}
  />
)

ArticleListContainer.propTypes = {
  articles: PropTypes.array,
}

ArticleListContainer.defaultProps = {
  articles: [],
}

const mapStateToProps = (state, props) => {
  const { docs, totalPages, currentPage } = listContainerPaginateProps(
    state, props, state.firestore.ordered.articles,
  )
  return {
    articles: docs,
    totalPages,
    currentPage,
  }
}

export default compose(
  firestoreConnect([{
    collection: 'articles',
    orderBy: ['createdDate', 'desc'],
  }]),
  connect(mapStateToProps),
)(ArticleListContainer)
