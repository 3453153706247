import React, { useState, useEffect } from 'react'
import { parse } from 'tldjs'
import axios from 'axios'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import { CloudFunctionsApi } from '../../lib/constants'

const RelatedExternalLink = ({ externalValue, updateValue, loading }) => {
  const [url, setUrl] = useState('')
  const [links, setLinks] = useState(externalValue)
  const [scraping, setScraping] = useState(false)
  const [error, setError] = useState(false)

  // set initial links value from prop
  useEffect(() => {
    setLinks(externalValue)
  }, [externalValue])

  const scrapeMetadata = (targetUrl) => {
    const cloudFunction = `${CloudFunctionsApi}scrapeUrl`
    const { isValid, tldExists } = parse(targetUrl)

    if (isValid && tldExists) {
      setScraping(true)

      axios.get(cloudFunction, {
        params: {
          targetUrl,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        mode: 'no-cors',
      }).then((response) => {
        const { data, success } = response.data
        // update form value
        if (success) {
          updateValue([
            ...links,
            data,
          ])
        }
        // reset field
        setUrl('')
        // set scraping false
        setScraping(false)
      }).catch((err) => {
        console.log(err)
        // set scraping false
        setScraping(false)
        setError(true)
      })
    } else {
      setError(true)
    }
  }

  const removeItem = (removeIndex) => {
    const updatedlinks = links.filter((link, index) => index !== removeIndex)
    updateValue(updatedlinks)
  }

  return (
    <Row>

      <Col md={12} className="align-self-end">
        <Form.Label>Related External</Form.Label>
        <InputGroup className="mb-3">

          <Form.Control
            type="text"
            value={url}
            style={error ? { color: 'red' } : {}}
            onChange={(e) => {
              setError(false)
              setUrl(e.target.value)
            }}
            disabled={loading || scraping}
          />
          <InputGroup.Append>
            <Button
              onClick={() => scrapeMetadata(url)}
              disabled={loading || scraping || url.length === 0}
            >
              {scraping ? 'Scraping' : 'Scrape'}
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Col>

      {links && (
        <Col md={12}>
          {
            links.map((link, index) => (
              <Row key={link.ogImage.url} className="related-item">
                <Col xs="3">
                  <img src={link.ogImage.url} alt="OG cover" />
                </Col>
                <Col>
                  <div><a href={link.ogUrl}>{link.ogTitle}</a></div>
                  <div><span>{link.ogUrl}</span></div>
                </Col>
                <Col xs="auto">
                  <Button onClick={() => removeItem(index)} variant="warning" disabled={loading || scraping}>Remove</Button>
                </Col>
              </Row>
            ))
          }
        </Col>
      )}

    </Row>
  )
}

export default RelatedExternalLink
