import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { ToastContainer } from 'react-toastify'
import { createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore
import { Provider } from 'react-redux'
import HeaderContainer from './containers/HeaderContainer'
import MainContainer from './containers/MainContainer'
import './lib/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import store from './lib/store'
import { reduxFirebaseConfig, firebase } from './lib/firebase'

const App = () => (
  <Provider store={store}>
    <ReactReduxFirebaseProvider
      firebase={firebase}
      config={reduxFirebaseConfig}
      dispatch={store.dispatch}
      createFirestoreInstance={createFirestoreInstance}
    >
      <Router>
        <div className="App">
          <HeaderContainer />
          <MainContainer />
          <ToastContainer
            position="bottom-right"
            closeOnClick
          />
        </div>
      </Router>
    </ReactReduxFirebaseProvider>
  </Provider>
)

export default App
