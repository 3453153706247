/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import { getFirebase } from 'react-redux-firebase'
import { useDropzone } from 'react-dropzone'
import { removeNil, transformEntryValues } from '../../lib/utils'
import MediaFormReducer, { initialState } from './MediaUploadFormReducer'
import {
  loadMedia,
  setFieldValue,
  setLocalizedFieldValue,
  setCheckingValidation,
  setUploading,
  setPreviewUrl,
  setLoading,
  setMediaError,
  resetMediaError,
  resetMediaForm,
} from './MediaUploadFormActions'
import { getCleanFilename, loadImageUrlAndSizes } from '../../lib/images'
import './MediaFormStyles.css'

const MediaUploadForm = ({
  media, showModal, setShowModal, setActiveMediaId,
}) => {
  // useReducer is a new react way to use a custom local reducer instead of the usual
  // local state. In this case, ArticleFormReducer is our reducer.

  const init = (initState) => {
    if (media) {
      const transformedFormValues = transformEntryValues(media)

      return {
        ...initState,
        formValues: transformedFormValues,
      }
    }
    return initState
  }

  const [formState, dispatch] = useReducer(
    MediaFormReducer,
    initialState,
    init,
  )

  const {
    formValues,
    error,
    loading,
    isUploading,
    isCheckingValidation,
  } = formState

  const {
    id,
    mediaUrl,
    metadata,
    localizedContent,
    width,
    height,
  } = formValues

  const { en, es } = localizedContent

  // useEffect is a new react way to have componentDid... type events in functional components

  useEffect(() => {
    if (media && id !== media.id) {
      const transformedFormValues = transformEntryValues(media)

      dispatch(loadMedia(transformedFormValues))
    }
  }, [media])

  const submitMediaForm = (data) => {
    const firebase = getFirebase()

    dispatch(resetMediaError())
    dispatch(setLoading(true))

    let docRef = firebase.firestore().collection('media').doc()

    // Check new or edit
    if (media) {
      docRef = firebase.firestore().collection('media').doc(media.id)
    }

    docRef.set(removeNil({
      createdDate: new Date().getTime(),
      ...data,
      updatedDate: new Date().getTime(),
    }))
      .then(() => {
        setActiveMediaId(docRef.id)

        dispatch(setLoading(false))

        // this is where we can trigger a notification or something
      })
      .catch(() => {
        // Error ocurred
        dispatch(setLoading(false))
        // dispatch(setMediaError(error))
      })
  }

  const onSubmitForm = (event) => {
    const form = event.currentTarget

    event.preventDefault()
    event.stopPropagation()

    if (form.checkValidity() === true) {
      submitMediaForm(formValues)
    }

    // start checking validation on the form now we have initial values
    dispatch(setCheckingValidation())
  }

  const removeMedia = () => {
    const firebase = getFirebase()

    dispatch(resetMediaError())
    dispatch(setLoading(true))

    // Confirm with user
    const confirmed = window.confirm('Are you sure you want to remove this media?')

    if (confirmed) {
      firebase.deleteFile(metadata.fullPath).then(() => {
        // Delete document
        dispatch(resetMediaForm())

        firebase.firestore().collection('media').doc(media.id).delete()
          .then(() => {
            setActiveMediaId(null)
            setShowModal(false)
            dispatch(setLoading(false))

            // this is where we can trigger a notification or something
          })
          .catch(() => {
            // Error ocurred
            dispatch(setLoading(false))
          })
      }).catch(() => {
        // Error ocurred
        dispatch(setLoading(false))
      })
    } else {
      dispatch(setLoading(false))
    }
  }

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    dispatch(resetMediaError())
    // if there are any rejected files list them and say they were rejected
    if (rejectedFiles.length > 0) {
      const fileList = rejectedFiles.map((file) => file.name).join(', ')

      return dispatch(setMediaError(`Files: ${fileList} rejected. Upload only 1 image file per media item.`))
    }

    // now upload the file if we have one
    const firebase = getFirebase()
    const filesPath = 'media'
    const uploadOptions = {
      progress: false, // disabled until the renaming bug is fixed in react-redux-firebase
      name: getCleanFilename,
    }

    dispatch(setUploading(true))

    firebase.uploadFile(filesPath, acceptedFiles[0], null, uploadOptions)
      .then(loadImageUrlAndSizes)
      .then((uploadResult) => {
        dispatch(setUploading(false))

        if (uploadResult.uploadTaskSnapshot.state === 'success') {
          // Set preview url (eventually a thumb)
          dispatch(setPreviewUrl(uploadResult.previewUrl))
          // Set mediaUri
          dispatch(setFieldValue('mediaUrl', uploadResult.previewUrl))
          // set the metadata returned in the form values state
          dispatch(setFieldValue('metadata', removeNil(uploadResult.uploadTaskSnapshot.metadata)))
          // set width and height
          dispatch(setFieldValue('width', uploadResult.width))
          dispatch(setFieldValue('height', uploadResult.height))

          setShowModal(true)

          submitMediaForm({
            ...formValues,
            mediaUrl: uploadResult.previewUrl,
            metadata: removeNil(uploadResult.uploadTaskSnapshot.metadata),
            width: uploadResult.width,
            height: uploadResult.height,
          })

          // at this moment should a form submit should be
          // called to make the first save into the database?
        }
      }).catch((err) => {
        dispatch(setUploading(false))
        console.log('File upload error:', err)
      })
    return undefined
  }, [])

  // setup dropzone props and settings
  const {
    getRootProps, getInputProps, isDragAccept, isDragReject,
  } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
    disabled: isUploading,
  })

  const dropzoneStyles = () => {
    let styles = 'dropzone-container'

    if (isDragAccept) {
      styles += ' active-accept'
    } else if (isDragReject) {
      styles += ' active-reject'
    }

    if (isUploading) {
      styles += ' active-uploading'
    }

    return styles
  }

  // if there is no metadata in form state show the dropzone
  // uploader, if there is show the edit form for title and caption
  return (
    <Container>
      <Row>
        <Col md={12}>
          <div className={dropzoneStyles()} {...getRootProps()}>
            <input {...getInputProps()} />
            <p className="dropzone-text">{ isUploading ? 'Uploading...' : 'Drag \'n\' drop some files here, or click to select files' }</p>
            { error && <p className="dropzone-error">{error}</p>}
          </div>
        </Col>
      </Row>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
          dispatch(resetMediaForm())
          setActiveMediaId(null)
        }}
        dialogClassName="media-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Media Metadata
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={isCheckingValidation}
            onSubmit={(event) => onSubmitForm(event)}
          >
            { mediaUrl
            && (
            <Row>
              <Col xs={8}>
                <Image src={mediaUrl} thumbnail />
                { width && height && (
                <p>
                  {width}
                  px x
                  {' '}
                  {height}
                  px
                </p>
                ) }
              </Col>

              <Col>
                <Form.Group controlId="actions">
                  <Form.Label>Actions</Form.Label>
                  <Row className="justify-content-between">
                    <Col>
                      <Button variant="primary" type="submit" disabled={loading} block>
                        { loading ? 'Loading…' : 'Save' }
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Button disabled={loading} variant="danger" onClick={() => removeMedia()} block>Remove</Button>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group controlId="es-title">
                  <Form.Label>Title Español</Form.Label>
                  <Form.Control type="text" value={es.title} onChange={(e) => dispatch(setLocalizedFieldValue('title', 'es', e.target.value))} disabled={loading} />
                </Form.Group>

                <Form.Group controlId="en-title">
                  <Form.Label>Title English</Form.Label>
                  <Form.Control type="text" value={en.title} onChange={(e) => dispatch(setLocalizedFieldValue('title', 'en', e.target.value))} disabled={loading} />
                </Form.Group>

                <Form.Group controlId="es-caption">
                  <Form.Label>Caption Español</Form.Label>
                  <Form.Control as="textarea" rows="3" type="text" value={es.caption} onChange={(e) => dispatch(setLocalizedFieldValue('caption', 'es', e.target.value))} disabled={loading} />
                </Form.Group>

                <Form.Group controlId="en-caption">
                  <Form.Label>Caption English</Form.Label>
                  <Form.Control as="textarea" rows="3" type="text" value={en.caption} onChange={(e) => dispatch(setLocalizedFieldValue('caption', 'en', e.target.value))} disabled={loading} />
                </Form.Group>
              </Col>
            </Row>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

MediaUploadForm.propTypes = {
  formState: PropTypes.object,
  initialFormState: PropTypes.object,
}

MediaUploadForm.defaultProps = {
  formState: {},
  initialFormState: {},
}

export default MediaUploadForm
