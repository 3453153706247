export const SET_MODAL_PAGE = 'SET_MODAL_PAGE'

// This is our initial state
const initialState = {
  modalPage: 0,
}

const PaginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_PAGE:
      return {
        ...state,
        modalPage: action.data,
      }
    default:
      return state
  }
}

export default PaginationReducer
