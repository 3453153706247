import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import MediaLibraryGrid from '../components/Media/MediaLibraryGrid'
import { listContainerPaginateProps } from '../lib/utils'

// In this case, we dont do isLoaded/isEmpty in the container
// we leverage that to <Articles /> because theres things to show
// even while loading
const MediaLibraryContainer = ({ media, totalPages, currentPage }) => (
  <MediaLibraryGrid
    media={media}
    totalPages={totalPages}
    currentPage={currentPage}
  />
)

MediaLibraryContainer.propTypes = {
  media: PropTypes.array,
}

MediaLibraryContainer.defaultProps = {
  media: [],
}

const mapStateToProps = (state, props) => {
  const { docs, totalPages, currentPage } = listContainerPaginateProps(
    state, props, state.firestore.ordered.media,
  )
  return {
    media: docs,
    totalPages,
    currentPage,
  }
}

export default compose(
  firestoreConnect([{
    collection: 'media',
    orderBy: ['createdDate', 'desc'],
  }]),
  connect(mapStateToProps),
)(MediaLibraryContainer)
