import {
  LOGIN_FORM_SET_FIELD_VALUE,
  LOGIN_FORM_SET_CHECKING_VALIDATION,
  LOGIN_FORM_SET_ERROR,
  LOGIN_FORM_SET_LOADING,
  LOGIN_FORM_RESET,
} from '../reducers/LoginReducer'

export const setFieldValue = (field, data) => ({
  type: LOGIN_FORM_SET_FIELD_VALUE,
  field,
  data,
})

export const setCheckingValidation = () => ({
  type: LOGIN_FORM_SET_CHECKING_VALIDATION,
})

export const setLoginError = (error = null) => ({
  type: LOGIN_FORM_SET_ERROR,
  data: error,
})

export const setLoading = (loading) => ({
  type: LOGIN_FORM_SET_LOADING,
  data: loading,
})

export const resetLoginForm = () => ({
  type: LOGIN_FORM_RESET,
})

export const logout = () => (dispatch, getState, getFirebase) => {
  const firebase = getFirebase()

  dispatch(setLoading(true))

  firebase.logout()
    .then(() => {
      dispatch(setLoading(false))
      // Logged out
      // this is where we can redirect to another route
    })
    .catch((error) => {
      console.error(error)
    })
}

export const submitLoginForm = () => (dispatch, getState, getFirebase) => {
  const { email, password } = getState().loginForm.formValues
  const firebase = getFirebase()

  dispatch(setLoginError())
  dispatch(setLoading(true))

  firebase.login({ email, password })
    .then(() => {
      dispatch(setLoading(false))
      dispatch(resetLoginForm())
      // Logged in
      // this is where we can redirect to another route
    })
    .catch((error) => {
      // Error ocurred
      dispatch(setLoading(false))
      dispatch(setLoginError(error))
    })
}
