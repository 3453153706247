import { useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form'
import { useFirestoreConnect } from 'react-redux-firebase'

function CitySelect({
  defaultValue,
  disabled,
  onBlur,
  onChange,
}) {
  const cities = useSelector((state) => state.firestore.data.cities)
  useFirestoreConnect(['cities'])
  return (
    <Form.Group controlId="city">
      <Form.Label>City</Form.Label>
      <Form.Control
        as="select"
        onBlur={onBlur}
        onChange={onChange}
        value={defaultValue}
        disabled={disabled}
      >
        {
          cities ? Object.keys(cities).map((key) => (
            <option value={key} key={key}>{cities[key].localizedContent.en.name}</option>
          )) : <option value="CDMX">Mexico City</option>
        }
      </Form.Control>
    </Form.Group>
  )
}

export default CitySelect
