import { generatePerLocale } from '../../lib/locales'

export const MEDIA_FORM_LOAD_MEDIA = 'MEDIA_FORM_LOAD_MEDIA'
export const MEDIA_FORM_SET_FIELD_VALUE = 'MEDIA_FORM_SET_FIELD_VALUE'
export const MEDIA_FORM_SET_LOCALIZED_FIELD_VALUE = 'MEDIA_FORM_SET_LOCALIZED_FIELD_VALUE'
export const MEDIA_FORM_SET_CHECKING_VALIDATION = 'MEDIA_FORM_SET_CHECKING_VALIDATION'
export const MEDIA_FORM_SET_ERROR = 'MEDIA_FORM_SET_ERROR'
export const MEDIA_FORM_RESET_ERROR = 'MEDIA_FORM_RESET_ERROR'
export const MEDIA_FORM_SET_UPLOADING = 'MEDIA_FORM_SET_UPLOADING'
export const MEDIA_FORM_SET_PREVIEW_URL = 'MEDIA_FORM_SET_PREVIEW_URL'
export const MEDIA_FORM_SET_LOADING = 'MEDIA_FORM_SET_LOADING'
export const MEDIA_FORM_RESET = 'MEDIA_FORM_RESET'
export const MEDIA_FORM_LOAD_DATA = 'MEDIA_FORM_LOAD_DATA'

// Post initial state
const postContentInitialState = {
  title: '',
  caption: '',
}

// This is our initial state
export const initialState = {
  isEditing: false,
  formValues: {
    metadata: {},
    localizedContent: generatePerLocale(postContentInitialState),
    width: false,
    height: false,
    mediaUrl: false,
  },
  mediaPreviewUrl: false,
  isCheckingValidation: false,
  error: false,
  loading: false,
}

const localizedContentReducer = (state = generatePerLocale(postContentInitialState), action) => {
  switch (action.type) {
    case MEDIA_FORM_SET_LOCALIZED_FIELD_VALUE:
      return {
        ...state,
        [action.locale]: {
          ...state[action.locale],
          [action.field]: action.data,
        },
      }
    default:
      return state
  }
}

const MediaUploadFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEDIA_FORM_LOAD_MEDIA:
      return {
        ...state,
        isEditing: true,
        formValues: action.data,
      }
    case MEDIA_FORM_SET_FIELD_VALUE:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.field]: action.data,
        },
      }
    case MEDIA_FORM_SET_LOCALIZED_FIELD_VALUE:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          localizedContent: localizedContentReducer(state.formValues.localizedContent, action),
        },
      }
    case MEDIA_FORM_SET_CHECKING_VALIDATION:
      return {
        ...state,
        isCheckingValidation: true,
      }
    case MEDIA_FORM_SET_ERROR: {
      return {
        ...state,
        error: action.data || false,
      }
    }
    case MEDIA_FORM_RESET_ERROR: {
      return {
        ...state,
        error: false,
      }
    }
    case MEDIA_FORM_SET_UPLOADING:
      return {
        ...state,
        isUploading: action.data,
      }
    case MEDIA_FORM_SET_PREVIEW_URL:
      return {
        ...state,
        mediaPreviewUrl: action.data,
      }
    case MEDIA_FORM_SET_LOADING: {
      return {
        ...state,
        loading: action.data,
      }
    }
    case MEDIA_FORM_RESET: {
      return initialState
    }
    default:
      return state
  }
}

export default MediaUploadFormReducer
