import React, { useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { convertToRaw } from 'draft-js'
import { getFirebase } from 'react-redux-firebase'
import DatePicker from '../Fields/DatePicker'
import RichEditor from '../Fields/RichEditor/RichEditor'
import Languages from '../Fields/Languages'
import MediaCarousel from '../Fields/MediaCarousel'
import MediaPicker from '../Media/MediaPicker'
import MediaPickerContainer from '../../containers/MediaPickerContainer'
import RelatedInternalContent from '../Fields/RelatedInternalContent'
import { statusOptions, articleTypeOptions } from '../../lib/constants'
import { ParseEditorContent, renderCommaList, parseCommaList } from '../../lib/utils'
import { transformDatepickerValue } from '../../lib/datetime'
import {
  setFieldValue, setLocalizedFieldValue, setCheckingValidation, setLoading, resetArticleError,
} from './ArticleFormActions'
import ArticleFormReducer, { initialState } from './ArticleFormReducer'

const ArticleForm = ({
  initialFormValues, articleId, related,
}) => {
  const history = useHistory()
  // useReducer is a new react way to use a custom local reducer instead of the usual
  // local state. In this case, ArticleFormReducer is our reducer.
  const [formState, dispatch] = useReducer(
    ArticleFormReducer, // Our Reducer
    initialState,
    () => { // This third parameter overrites our initialstaate
      if (articleId) {
        // NEED TO PARSE EDITOR CONTENT FROM INITIAL FORM VALUES
        return {
          ...initialState,
          formValues: {
            ...initialFormValues,
            localizedContent: {
              en: {
                ...initialFormValues.localizedContent.en,
                mainContent: ParseEditorContent(initialFormValues.localizedContent.en.mainContent),
              },
              es: {
                ...initialFormValues.localizedContent.es,
                mainContent: ParseEditorContent(initialFormValues.localizedContent.es.mainContent),
              },
            },
          },
        }
      }
      return initialState
    },
  )

  const {
    formValues,
    loading,
    isCheckingValidation,
  } = formState

  const {
    localizedContent,
    author,
    publishDate,
    imageCarousel,
    coverImage,
    status,
    type,
    keywords,
    languages,
    slug,
  } = formValues

  const { en, es } = localizedContent

  const submitArticleForm = (data) => {
    const firebase = getFirebase()

    dispatch(resetArticleError())
    dispatch(setLoading(true))

    let docRef = firebase.firestore().collection('articles').doc()

    // Check new or edit
    if (articleId) {
      docRef = firebase.firestore().collection('articles').doc(articleId)
    }

    let newSlug = slug
    if (slug === undefined || slug === '' || slug.includes('_')) {
      const slugTitle = data.localizedContent.es.title
        ? data.localizedContent.es.title : data.localizedContent.en.title

      newSlug = slugTitle ? slugTitle.toLowerCase().replace(/[^A-Z0-9]+/ig, '-') : ''
    }

    docRef.set({
      createdDate: new Date().getTime(),
      ...data,
      slug: newSlug,
      updatedDate: new Date().getTime(),
      // REFACTOR
      localizedContent: {
        es: {
          ...data.localizedContent.es,
          mainContent: JSON.stringify(
            convertToRaw(data.localizedContent.es.mainContent.getCurrentContent()),
          ),
        },
        en: {
          ...data.localizedContent.en,
          mainContent: JSON.stringify(
            convertToRaw(data.localizedContent.en.mainContent.getCurrentContent()),
          ),
        },
      },
      // convert date values to explicit CDMX timestamps
      publishDate: transformDatepickerValue(data.publishDate),
      // Convert editorState to Raw content
    })
      .then(() => {
        dispatch(setLoading(false))
        if (!articleId) {
          history.push(`/article/edit/${docRef.id}`)
        }
        // this is where we can trigger a notification or something
      })
      .catch(() => {
        // Error ocurred
        dispatch(setLoading(false))
        // dispatch(setArticleError(error))
      })
  }

  const onSubmitForm = (event) => {
    const form = event.currentTarget

    event.preventDefault()
    event.stopPropagation()

    if (form.checkValidity() === true) {
      submitArticleForm(formValues)
    }

    // start checking validation on the form now we have initial values
    dispatch(setCheckingValidation())
  }

  const removeArticle = (selectedId) => {
    const firebase = getFirebase()

    if (selectedId) {
      // Confirm with user
      const confirmed = window.confirm('Are you sure you want to remove this article?')

      if (confirmed) {
        // Delete document
        firebase.firestore().collection('articles').doc(selectedId).delete()
          .then(() => {
            // Redirect to /articles
            history.push('/articles')
            // this is where we can trigger a notification or something
          })
          .catch(() => {
            // Error ocurred
            dispatch(setLoading(false))
          })
      }
    }
  }

  return (
    <Container>
      <h2>{articleId ? 'Article' : 'New Article'}</h2>

      <Form
        noValidate
        validated={isCheckingValidation}
        onSubmit={(event) => onSubmitForm(event)}
      >
        <Row>
          <Col md={8}>
            <Row>
              <Col md={12}>
                <Form.Group controlId="es-title">
                  <Form.Label>Title Español</Form.Label>
                  <Form.Control type="text" value={es.title} onChange={(e) => dispatch(setLocalizedFieldValue('title', 'es', e.target.value))} disabled={loading} required />
                  <Form.Control.Feedback type="invalid">Please provide a valid title.</Form.Control.Feedback>
                </Form.Group>
              </Col>

            </Row>
            <Row>

              <Col md={12}>
                <Form.Group controlId="en-title">
                  <Form.Label>Title English</Form.Label>
                  <Form.Control type="text" value={en.title} onChange={(e) => dispatch(setLocalizedFieldValue('title', 'en', e.target.value))} disabled={loading} required />
                  <Form.Control.Feedback type="invalid">Please provide a valid title.</Form.Control.Feedback>
                </Form.Group>
              </Col>

            </Row>
            <Row>

              <Col md={12}>
                <Form.Group controlId="es-subtitle">
                  <Form.Label>Subtitle Español</Form.Label>
                  <Form.Control type="text" value={es.subtitle} onChange={(e) => dispatch(setLocalizedFieldValue('subtitle', 'es', e.target.value))} disabled={loading} />
                </Form.Group>
              </Col>

            </Row>
            <Row>

              <Col md={12}>
                <Form.Group controlId="en-subtitle">
                  <Form.Label>Subtitle English</Form.Label>
                  <Form.Control type="text" value={en.subtitle} onChange={(e) => dispatch(setLocalizedFieldValue('subtitle', 'en', e.target.value))} disabled={loading} />
                </Form.Group>
              </Col>

            </Row>
            <Row>

              <Col md={6}>
                <Form.Group controlId="author">
                  <Form.Label>Author</Form.Label>
                  <Form.Control type="text" value={author} onChange={(e) => dispatch(setFieldValue('author', e.target.value))} disabled={loading} required />
                  <Form.Control.Feedback type="invalid">Please provide a valid author.</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="publishDate">
                  <Form.Label>Publish Date / Time</Form.Label>
                  <DatePicker
                    selected={publishDate}
                    onChange={(date) => dispatch(setFieldValue('publishDate', date))}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="d MMMM, yyyy h:mm aa"
                    timeCaption="time"
                    fixedHeight
                    className="form-control"
                    allowSameDay
                    isClearable
                  />
                </Form.Group>
              </Col>

            </Row>

            <MediaPickerContainer value={imageCarousel} handleOnChange={(value) => dispatch(setFieldValue('imageCarousel', value))} PickerComponent={MediaCarousel} />

            <Row>

              <Col md={6}>
                <Form.Group controlId="es-content">
                  <Form.Label>Content Español</Form.Label>
                  <RichEditor
                    editorState={es.mainContent}
                    onEditorStateChange={(editorState) => {
                      dispatch(setLocalizedFieldValue('mainContent', 'es', editorState))
                    }}
                    supportImages
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="en-content">
                  <Form.Label>Content English</Form.Label>
                  <RichEditor
                    editorState={en.mainContent}
                    onEditorStateChange={(editorState) => {
                      dispatch(setLocalizedFieldValue('mainContent', 'en', editorState))
                    }}
                    supportImages
                  />
                </Form.Group>
              </Col>

            </Row>

            <RelatedInternalContent
              docId={articleId}
              loading={loading}
              related={related}
              collection="articles"
            />

          </Col>

          <Col>
            <Form.Group controlId="actions">
              <Form.Label>Actions</Form.Label>
              <Row className="justify-content-between">
                <Col>
                  <Button variant="primary" type="submit" disabled={loading} block>
                    { loading ? 'Loading…' : 'Save' }
                  </Button>
                </Col>
                { articleId
                  && (
                  <Col xs="auto">
                    <Button disabled={loading} variant="danger" onClick={() => removeArticle(articleId)} block>Remove</Button>
                  </Col>
                  )}
              </Row>
            </Form.Group>

            {slug !== undefined && slug !== ''
              && (
              <Form.Group controlId="slug">
                <Form.Label>Permalink</Form.Label>
                <div><a href={`https://ondamx.art/escrito/${slug}-${articleId}`}>{`https://ondamx.art/escrito/${slug}-${articleId}`}</a></div>
              </Form.Group>
              )}

            <Form.Group controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control as="select" onChange={(e) => dispatch(setFieldValue('status', e.target.value))} defaultValue={status} disabled={loading}>
                { Object.keys(statusOptions).map((key) => (
                  <option value={key} key={key}>{statusOptions[key]}</option>
                )) }
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="type">
              <Form.Label>Type</Form.Label>
              <Form.Control as="select" onChange={(e) => dispatch(setFieldValue('type', e.target.value))} defaultValue={type} disabled={loading}>
                {
                Object.keys(articleTypeOptions).map((key) => (
                  <option
                    value={key}
                    key={key}
                  >
                    {articleTypeOptions[key]}
                  </option>
                ))
                }
              </Form.Control>
            </Form.Group>

            <Languages value={languages} onChange={(value) => dispatch(setFieldValue('languages', value))} />

            <Form.Group controlId="keywords">
              <Form.Label>Keywords</Form.Label>
              <Form.Control as="textarea" rows="3" value={renderCommaList(keywords)} onChange={(e) => dispatch(setFieldValue('keywords', parseCommaList(e.target.value)))} />
            </Form.Group>

            <MediaPickerContainer value={coverImage} handleOnChange={(value) => dispatch(setFieldValue('coverImage', value))} PickerComponent={MediaPicker} label="Cover Image" />

            <Form.Group controlId="es-seo-title">
              <Form.Label>SEO Title Español</Form.Label>
              <Form.Control value={es.seoTitle} onChange={(e) => dispatch(setLocalizedFieldValue('seoTitle', 'es', e.target.value))} />
            </Form.Group>

            <Form.Group controlId="en-seo-title">
              <Form.Label>SEO Title English</Form.Label>
              <Form.Control value={en.seoTitle} onChange={(e) => dispatch(setLocalizedFieldValue('seoTitle', 'en', e.target.value))} />
            </Form.Group>

            <Form.Group controlId="es-seo-description">
              <Form.Label>SEO Description Español</Form.Label>
              <Form.Control as="textarea" rows="3" value={es.seoDescription} onChange={(e) => dispatch(setLocalizedFieldValue('seoDescription', 'es', e.target.value))} />
            </Form.Group>

            <Form.Group controlId="en-seo-description">
              <Form.Label>SEO Description English</Form.Label>
              <Form.Control as="textarea" rows="3" value={en.seoDescription} onChange={(e) => dispatch(setLocalizedFieldValue('seoDescription', 'en', e.target.value))} />
            </Form.Group>

          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default ArticleForm
