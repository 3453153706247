import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import usePagination from '../hooks/usePagination'
import CityList from '../components/Cities/CityList'
import { listContainerPaginateProps } from '../lib/utils'

const CityListContainer = () => {
  const cities = useSelector((state) => state.firestore.ordered.cities)
  useFirestoreConnect(['cities'])
  const { docs, totalPages, currentPage } = usePagination(cities)

  return (
    <CityList
      cities={docs}
      totalPages={totalPages}
      currentPage={currentPage}
    />
  )
}

export default CityListContainer
