export const LOGIN_FORM_SET_FIELD_VALUE = 'LOGIN_FORM_SET_FIELD_VALUE'
export const LOGIN_FORM_SET_CHECKING_VALIDATION = 'LOGIN_FORM_SET_CHECKING_VALIDATION'
export const LOGIN_FORM_SET_ERROR = 'LOGIN_FORM_SET_ERROR'
export const LOGIN_FORM_SET_LOADING = 'LOGIN_FORM_SET_LOADING'
export const LOGIN_FORM_RESET = 'LOGIN_FORM_RESET'

// This is our initial state
const initialState = {
  formValues: {
    email: '',
    password: '',
  },
  isCheckingValidation: false,
  error: false,
  loading: false,
}

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_FORM_SET_FIELD_VALUE:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.field]: action.data,
        },
      }
    case LOGIN_FORM_SET_CHECKING_VALIDATION:
      return {
        ...state,
        isCheckingValidation: true,
      }
    case LOGIN_FORM_SET_ERROR: {
      return {
        ...state,
        error: action.data,
      }
    }
    case LOGIN_FORM_SET_LOADING: {
      return {
        ...state,
        loading: action.data,
      }
    }
    case LOGIN_FORM_RESET: {
      return initialState
    }
    default:
      return state
  }
}

export default LoginReducer
