import {
  ARTICLE_FORM_SET_FIELD_VALUE,
  ARTICLE_FORM_SET_LOCALIZED_FIELD_VALUE,
  ARTICLE_FORM_SET_CHECKING_VALIDATION,
  ARTICLE_FORM_SET_ERROR,
  ARTICLE_FORM_RESET_ERROR,
  ARTICLE_FORM_SET_LOADING,
  ARTICLE_FORM_LOAD_DATA,
  ARTICLE_FORM_RESET,
} from './ArticleFormReducer'

export const setFieldValue = (field, data) => ({
  type: ARTICLE_FORM_SET_FIELD_VALUE,
  field,
  data,
})

export const setLocalizedFieldValue = (field, locale, data) => ({
  type: ARTICLE_FORM_SET_LOCALIZED_FIELD_VALUE,
  field,
  locale,
  data,
})

export const setCheckingValidation = () => ({
  type: ARTICLE_FORM_SET_CHECKING_VALIDATION,
})

export const setArticleError = (error = null) => ({
  type: ARTICLE_FORM_SET_ERROR,
  data: error,
})

export const resetArticleError = () => ({
  type: ARTICLE_FORM_RESET_ERROR,
})

export const setLoading = (loading) => ({
  type: ARTICLE_FORM_SET_LOADING,
  data: loading,
})

export const loadArticleData = (data) => ({
  type: ARTICLE_FORM_LOAD_DATA,
  data,
})

export const resetArticleForm = () => ({
  type: ARTICLE_FORM_RESET,
})
