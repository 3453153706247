import React, { useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { Client } from '@googlemaps/google-maps-services-js'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import LocationMarker from './LocationMarker'

const mapsKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
|| process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY

const client = new Client({})

const LocationField = ({ locationState, loading, onLocationStateChange }) => {
  const { address } = locationState

  const [loadingLocation, setLoadingLocation] = useState(false)

  const handleAddressChange = (key, value) => onLocationStateChange({
    ...locationState,
    address: {
      ...locationState.address,
      [key]: value,
    },
  })

  const handleGeoLocationStateChange = (value) => onLocationStateChange({
    ...locationState,
    ...value,
  })

  const searchAddress = () => {
    setLoadingLocation(true)
    if (address.street === undefined || address.street === '') {
      setLoadingLocation(false)
      return alert('Enter an address to search first!')
    }

    const searchString = `${address.number} ${address.street}${address.neighborhood === '' ? '' : `, ${address.neighborhood}`}. ${address.city} ${address.country} ${address.postalCode}`.replace('undefined', '')

    client.geocode({
      params: {
        address: searchString,
        key: mapsKey,
      },
    }, axios)
      .then((response) => {
        setLoadingLocation(false)
        if (response.data.results.length) {
          const locationData = response.data.results[0].geometry.location
          const { lat, lng } = locationData

          // set the location value in state
          handleGeoLocationStateChange({
            lat,
            lon: lng,
          })
        }
      })
      .catch((err) => {
        setLoadingLocation(false)
        console.error('Geocode Error: ', err)
      })
    return undefined
  }

  const clearLocation = () => {
    // Confirm with user
    const confirmed = window.confirm('Are you sure you want to clear this location?')

    if (confirmed) {
      return onLocationStateChange({
        address: {
          street: '',
          number: '',
          extra: '',
          neighborhood: '',
          city: '',
          state: '',
          country: '',
          postalCode: '',
        },
        lat: 0,
        lon: 0,
      })
    }

    return null
  }

  const hasLocation = locationState.lat !== 0 && locationState.lon !== 0

  return (
    <div className="location-field-wrapper">
      <Row>
        <Col md={9}>
          <Form.Group controlId="Street">
            <Form.Label>Street</Form.Label>
            <Form.Control type="text" value={address.street} onChange={(e) => { handleAddressChange('street', e.target.value) }} disabled={loading} />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="Street">
            <Form.Label>Number</Form.Label>
            <Form.Control type="text" value={address.number} onChange={(e) => { handleAddressChange('number', e.target.value) }} disabled={loading} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Group controlId="Extra">
            <Form.Label>Extra (interior number, door bell, etc)</Form.Label>
            <Form.Control type="text" value={address.extra} onChange={(e) => { handleAddressChange('extra', e.target.value) }} disabled={loading} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Group controlId="Neighborhood">
            <Form.Label>Neighborhood</Form.Label>
            <Form.Control type="text" value={address.neighborhood} onChange={(e) => { handleAddressChange('neighborhood', e.target.value) }} disabled={loading} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group controlId="City">
            <Form.Label>City / Delegación</Form.Label>
            <Form.Control type="text" value={address.city} onChange={(e) => { handleAddressChange('city', e.target.value) }} disabled={loading} />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="State">
            <Form.Label>State</Form.Label>
            <Form.Control type="text" value={address.state} onChange={(e) => { handleAddressChange('state', e.target.value) }} disabled={loading} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Form.Group controlId="Country">
            <Form.Label>Country</Form.Label>
            <Form.Control type="text" value={address.country} onChange={(e) => { handleAddressChange('country', e.target.value) }} disabled={loading} />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="Postal Code">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control type="text" value={address.postalCode} onChange={(e) => { handleAddressChange('postalCode', e.target.value) }} disabled={loading} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Group>
            <Row>
              <Col xs="auto">
                <Button variant="primary" disabled={loadingLocation} onClick={() => { searchAddress() }}>{loadingLocation ? 'Loading Location…' : 'Search Address'}</Button>
              </Col>
              { hasLocation
                && (
                <Col xs="auto">
                  <Button disabled={loadingLocation} variant="warning" onClick={() => clearLocation()} block>Clear Location</Button>
                </Col>
                )}
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Group style={{ height: '400px' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: mapsKey,
              }}
              center={hasLocation ? {
                lat: locationState.lat,
                lng: locationState.lon,
              } : {
                lat: 19.432608,
                lng: -99.133209,
              }}
              zoom={hasLocation ? 17 : 10}
            >
              {hasLocation
                && <LocationMarker lat={locationState.lat} lng={locationState.lon} />}
            </GoogleMapReact>
          </Form.Group>
        </Col>
      </Row>
    </div>
  )
}

export default LocationField
