import React, { Component } from 'react'
import Day from './HoursDay'

export default class Hours extends Component {
  constructor(props) {
    super(props)

    const { value } = props
    this.state = {
      hours: value !== undefined ? value : {
        monday: [null, null],
        tuesday: [null, null],
        wednesday: [null, null],
        thursday: [null, null],
        friday: [null, null],
        saturday: [null, null],
        sunday: [null, null],
      },
    }
  }

  handleDateChange(date, day, index) {
    const { hours } = this.state
    const { onChange } = this.props
    const dayValue = hours[day]
    dayValue[index] = date

    this.setState((prevState) => ({
      hours: {
        ...prevState.hours,
        [day]: dayValue,
      },
    }))

    onChange(hours)
  }

  render() {
    const { value } = this.props
    const {
      monday = [],
      tuesday = [],
      wednesday = [],
      thursday = [],
      friday = [],
      saturday = [],
      sunday = [],
    } = value
    return (
      <>
        <Day
          day="Monday"
          value={monday}
          handleChange={(date, index) => this.handleDateChange(date, 'monday', index)}
        />
        <Day
          day="Tuesday"
          value={tuesday}
          handleChange={(date, index) => this.handleDateChange(date, 'tuesday', index)}
        />
        <Day
          day="Wednesday"
          value={wednesday}
          handleChange={(date, index) => this.handleDateChange(date, 'wednesday', index)}
        />
        <Day
          day="Thursday"
          value={thursday}
          handleChange={(date, index) => this.handleDateChange(date, 'thursday', index)}
        />
        <Day
          day="Friday"
          value={friday}
          handleChange={(date, index) => this.handleDateChange(date, 'friday', index)}
        />
        <Day
          day="Saturday"
          value={saturday}
          handleChange={(date, index) => this.handleDateChange(date, 'saturday', index)}
        />
        <Day
          day="Sunday"
          value={sunday}
          handleChange={(date, index) => this.handleDateChange(date, 'sunday', index)}
        />
      </>
    )
  }
}
