import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Pagination from '../Pagination'

const EventList = ({
  events, totalPages, currentPage,
}) => (
  <Container>
    <Row>
      <Col>
        <h2>Events</h2>
      </Col>
      <Col xs="auto">
        <Link to="/event/new">
          <Button>Add New</Button>
        </Link>
      </Col>
    </Row>

    { !isLoaded(events)
        && <div>Loading...</div>}

    { isLoaded(events) && isEmpty(events)
        && <div>Events List Is Empty</div>}

    { isLoaded(events) && !isEmpty(events) && (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              Name
            </th>
            <th>
              City
            </th>
            <th>
              Space
            </th>
            <th>
              Type
            </th>
            <th>
              Opening
            </th>
          </tr>
        </thead>
        <tbody>
          { events.map((event) => (
            <tr key={event.id}>
              <td>
                <Link to={`/event/edit/${event.id}`}>{event.localizedContent.es.name}</Link>
              </td>
              <td>
                <span>{event.city}</span>
              </td>
              <td>
                <span>{event.space.name}</span>
              </td>
              <td>
                <span>{event.type}</span>
              </td>
              <td>
                {event.openingStart
                        && (
                        <span>
                          {new Date(event.openingStart.seconds * 1000).toLocaleString('default', {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                          })}
                        </span>
                        )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination totalPages={totalPages} currentPage={currentPage} list="events" />
    </>
    )}
  </Container>
)

EventList.propTypes = {
  events: PropTypes.array,
}

EventList.defaultProps = {
  events: [],
}

export default EventList
