/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import './RichEditor.css'

const VideoButton = ({ editorState, active, onToggle }) => {
  const handleOnToggle = (e) => {
    e.preventDefault()
    onToggle()
  }
  return (
    <div className={active ? 'editor-style-button active' : 'editor-style-button'} onMouseDown={handleOnToggle}>
      Youtube
    </div>
  )
}

export default VideoButton
