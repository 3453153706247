import React, { useState } from 'react'
import Editor from 'draft-js-plugins-editor'
import { RichUtils, Entity } from 'draft-js'
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent'
import { filterEditorState } from 'draftjs-filters'
import createImagePlugin from 'draft-js-image-plugin'
import createVideoPlugin from 'draft-js-video-plugin'
import 'draft-js-image-plugin/lib/plugin.css'
import './RichEditor.css'
import BlockStyleControls from './BlockStyleControls'
import InlineStyleControls from './InlineStyleControls'
import EditorMediaPicker from './EditorMediaPicker'
import UrlInput from './UrlInput'
import MediaPickerContainer from '../../../containers/MediaPickerContainer'

const videoPlugin = createVideoPlugin()
const imagePlugin = createImagePlugin()
const plugins = [imagePlugin, videoPlugin]

const RichEditor = ({ onEditorStateChange, editorState, supportImages }) => {
  const [urlInputActive, setUrlInputActive] = useState(false)
  const [urlInputValue, setUrlInputValue] = useState('')

  const [videoInputActive, setVideoInputActive] = useState(false)
  const [videoInputValue, setVideoInputValue] = useState('')

  const Link = ({ entityKey, children }) => {
    const { url } = Entity.get(entityKey).getData()
    return (
      <a href={url} className="draftLink">{children}</a>
    )
  }

  const findLinkEntities = (contentBlock, callback) => {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity()
        return (
          entityKey !== null
          && Entity.get(entityKey).getType() === 'LINK'
        )
      },
      callback,
    )
  }

  const decorator = [
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]

  const onChange = (nextState) => {
    let filteredState = nextState

    const shouldFilterPaste = nextState.getCurrentContent() !== editorState.getCurrentContent()
    && nextState.getLastChangeType() === 'insert-fragment'

    if (shouldFilterPaste) {
      filteredState = filterEditorState({
        blocks: [
          'header-two',
          'unordered-list-item',
          'ordered-list-item',
        ],
        styles: [
          'ITALIC',
        ],
        entities: [
          {
            type: 'LINK',
            attributes: ['url'],
          },
        ],
        maxNesting: 1,
        whitespacedCharacters: ['\n', '\t'],
      }, filteredState)
    }

    onEditorStateChange(filteredState)
  }

  const toggleBlockType = (blockType) => {
    onChange(
      RichUtils.toggleBlockType(
        editorState,
        blockType,
      ),
    )
  }

  const toggleInlineStyle = (inlineStyle) => {
    onChange(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle,
      ),
    )
  }

  const toggleLinkEntity = () => {
    const linkActive = RichUtils.currentBlockContainsLink(editorState)
    const selection = editorState.getSelection()

    if (!selection.isCollapsed()) {
      if (linkActive) {
        onChange(RichUtils.toggleLink(editorState, selection, null))
        setUrlInputActive(false)
      } else {
        setUrlInputActive(!urlInputActive)
      }
    }
    setUrlInputValue('')
  }

  const handleConfirmUrl = () => {
    const entityKey = Entity.create('LINK', 'MUTABLE', { url: urlInputValue })
    onChange(RichUtils.toggleLink(
      editorState,
      editorState.getSelection(),
      entityKey,
    ))
    setUrlInputValue('')
    setUrlInputActive(false)
  }

  const handleAddVideo = () => {
    onEditorStateChange(videoPlugin.addVideo(editorState, { src: videoInputValue }))
    setVideoInputValue('')
    setVideoInputActive(false)
  }

  const handleReturn = (event) => {
    if (isSoftNewlineEvent(event)) {
      onChange(RichUtils.insertSoftNewline(editorState))
      return 'handled'
    }
    return 'not-handled'
  }

  return (
    <>
      <div className="RichEditor-root editor-wrapper form-control">
        <div className="editor-controls-wrapper">
          <BlockStyleControls
            editorState={editorState}
            onToggle={toggleBlockType}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={toggleInlineStyle}
            onLinkToggle={toggleLinkEntity}
            urlInputActive={urlInputActive}
            onVideoToggle={() => {
              setVideoInputActive(!videoInputActive)
            }}
            videoInputActive={videoInputActive}
          />
          <UrlInput
            active={urlInputActive}
            value={urlInputValue}
            setValue={setUrlInputValue}
            onConfirm={handleConfirmUrl}
          />
          <UrlInput
            active={videoInputActive}
            value={videoInputValue}
            setValue={setVideoInputValue}
            onConfirm={handleAddVideo}
          />
        </div>
        <Editor
          editorState={editorState}
          onChange={onChange}
          plugins={plugins}
          handleReturn={handleReturn}
          decorators={decorator}
        />
      </div>
      {supportImages
        && (
        <MediaPickerContainer
          PickerComponent={EditorMediaPicker}
          editorState={editorState}
          handleOnChange={(state) => onEditorStateChange(state)}
          modifier={imagePlugin.addImage}
        />
        )}
    </>
  )
}

export default RichEditor
