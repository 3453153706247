import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import SpaceList from '../components/Space/SpaceList'
import { listContainerPaginateProps } from '../lib/utils'

// In this case, we dont do isLoaded/isEmpty in the container
// we leverage that to <Spaces /> because theres things to show
// even while loading
const SpaceListContainer = ({ spaces, totalPages, currentPage }) => (
  <SpaceList
    spaces={spaces}
    totalPages={totalPages}
    currentPage={currentPage}
  />
)

SpaceListContainer.propTypes = {
  spaces: PropTypes.array,
}

SpaceListContainer.defaultProps = {
  spaces: [],
}

const mapStateToProps = (state, props) => {
  const { docs, totalPages, currentPage } = listContainerPaginateProps(
    state, props, state.firestore.ordered.spaces,
  )
  return {
    spaces: docs,
    totalPages,
    currentPage,
  }
}

export default compose(
  firestoreConnect([{
    collection: 'spaces',
    orderBy: ['createdDate', 'desc'],
  }]),
  connect(mapStateToProps),
)(SpaceListContainer)
