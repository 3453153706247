import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { getMediaThumb } from '../../lib/images'
import Pagination from '../Pagination'
import MediaUploadForm from './MediaUploadForm'

const MediaLibraryGrid = ({
  media, totalPages, currentPage,
}) => {
  const [activeMediaId, setActiveMediaId] = useState(null)
  const [showModal, setShowModal] = useState(false)

  return (
    <div>
      <Container>
        <Row className="justify-content-between">
          <Col>
            <h2>Media Library</h2>
          </Col>
        </Row>
      </Container>

      <MediaUploadForm
        media={activeMediaId
          && isLoaded(media) ? media.find((item) => item.id === activeMediaId) : false}
        showModal={showModal}
        setShowModal={setShowModal}
        setActiveMediaId={setActiveMediaId}
      />

      <Container>
        { !isLoaded(media)
          && (
          <Row className="justify-content-between">
            <Col>
              <div>Loading...</div>
            </Col>
          </Row>
          )}

        { isLoaded(media) && isEmpty(media)
          && (
          <Row className="justify-content-between">
            <Col>
              <div>Media Library Is Empty</div>
            </Col>
          </Row>
          )}

        { isLoaded(media) && !isEmpty(media) && (
          <>
            <Row>
              { media.map((mediaItem) => {
                const thumbSrc = getMediaThumb(mediaItem.mediaUrl)
                return (
                  <Col
                    md={4}
                    xl={3}
                    key={mediaItem.id}
                    onClick={() => {
                      setActiveMediaId(mediaItem.id)
                      setShowModal(true)
                    }}
                    style={{
                      marginBottom: '1rem',
                    }}
                  >
                    { thumbSrc
                      && <Image className="u-pointer" src={thumbSrc} thumbnail />}
                  </Col>
                )
              })}
            </Row>
            <Pagination totalPages={totalPages} currentPage={currentPage} list="media-library" />
          </>
        )}
      </Container>
    </div>
  )
}

MediaLibraryGrid.propTypes = {
  media: PropTypes.array,
}

MediaLibraryGrid.defaultProps = {
  media: [],
}

export default MediaLibraryGrid
