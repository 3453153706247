import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import EventList from '../components/Event/EventList'
import { listContainerPaginateProps } from '../lib/utils'

// In this case, we dont do isLoaded/isEmpty in the container
// we leverage that to <Events /> because theres things to show
// even while loading
const EventListContainer = ({ events, totalPages, currentPage }) => (
  <EventList
    events={events}
    totalPages={totalPages}
    currentPage={currentPage}
  />
)

EventListContainer.propTypes = {
  events: PropTypes.array,
}

EventListContainer.defaultProps = {
  events: [],
}

const mapStateToProps = (state, props) => {
  const { docs, totalPages, currentPage } = listContainerPaginateProps(
    state, props, state.firestore.ordered.events,
  )
  return {
    events: docs,
    totalPages,
    currentPage,
  }
}

export default compose(
  firestoreConnect([{
    collection: 'events',
    orderBy: ['createdDate', 'desc'],
  }]),
  connect(mapStateToProps),
)(EventListContainer)
