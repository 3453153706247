/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import './RichEditor.css'

const StyleButton = ({
  style, label, active, onToggle,
}) => {
  const handleOnToggle = (e) => {
    e.preventDefault()
    onToggle(style)
  }
  return (
    <div className={active ? 'editor-style-button active' : 'editor-style-button'} onMouseDown={handleOnToggle}>
      {label}
    </div>
  )
}

export default StyleButton
