import React, { useReducer } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { convertToRaw } from 'draft-js'
import { getFirebase } from 'react-redux-firebase'
import RichEditor from '../Fields/RichEditor/RichEditor'
import MediaPicker from '../Media/MediaPicker'
import MediaPickerContainer from '../../containers/MediaPickerContainer'
import { ParseEditorContent } from '../../lib/utils'
import { transformDatepickerValue } from '../../lib/datetime'
import SettingsClientFormReducer, { initialState } from './SettingsClientFormReducer'
import {
  setLocalizedFieldValue, setCheckingValidation, setLoading, resetSettingsError,
} from './SettingsClientFormActions'

const SettingsClientForm = ({ initialFormValues, settingsId }) => {
  // useReducer is a new react way to use a custom local reducer instead of the usual
  // local state. In this case, SettingsFormReducer is our reducer.
  const [formState, dispatch] = useReducer(
    SettingsClientFormReducer, // Our Reducer
    initialState,
    () => { // This third parameter overrites our initialstaate
      if (settingsId) {
        // NEED TO PARSE EDITOR CONTENT FROM INITIAL FORM VALUES
        return {
          ...initialState,
          formValues: {
            ...initialFormValues,
            localizedContent: {
              en: {
                ...initialFormValues.localizedContent.en,
                featuredIntro: ParseEditorContent(
                  initialFormValues.localizedContent.en.featuredIntro,
                ),
              },
              es: {
                ...initialFormValues.localizedContent.es,
                featuredIntro: ParseEditorContent(
                  initialFormValues.localizedContent.es.featuredIntro,
                ),
              },
            },
          },
        }
      }
      return initialState
    },
  )

  const {
    formValues,
    loading,
    isCheckingValidation,
  } = formState

  const {
    localizedContent,
  } = formValues

  const { en, es } = localizedContent

  const submitSettingsForm = (data) => {
    const firebase = getFirebase()

    dispatch(resetSettingsError())
    dispatch(setLoading(true))

    const docRef = firebase.firestore().collection('settings').doc(settingsId)

    const body = {
      createdDate: new Date().getTime(),
      ...data,
      updatedDate: new Date().getTime(),
      // REFACTOR
      localizedContent: {
        es: {
          ...data.localizedContent.es,
          featuredIntro: JSON.stringify(convertToRaw(
            data.localizedContent.es.featuredIntro.getCurrentContent(),
          )),
        },
        en: {
          ...data.localizedContent.en,
          featuredIntro: JSON.stringify(convertToRaw(
            data.localizedContent.en.featuredIntro.getCurrentContent(),
          )),
        },
      },
      // convert date values to explicit CDMX timestamps
      publishDate: transformDatepickerValue(data.publishDate),
      // Convert editorState to Raw content
    }
    docRef.set(body)
      .then(() => {
        dispatch(setLoading(false))
        // this is where we can trigger a notification or something
      })
      .catch((error) => {
        // Error ocurred
        dispatch(setLoading(false))
        // dispatch(setSettingsError(error))
      })
  }

  const onSubmitForm = (event) => {
    const form = event.currentTarget

    event.preventDefault()
    event.stopPropagation()

    if (form.checkValidity() === true) {
      submitSettingsForm(formValues)
    }

    // start checking validation on the form now we have initial values
    dispatch(setCheckingValidation())
  }

  return (
    <Container>
      <h2>Client Settings</h2>

      <Form
        noValidate
        validated={isCheckingValidation}
        onSubmit={(event) => onSubmitForm(event)}
      >
        <Row>
          <Col md={8}>

            <Row>
              <Col md={6}>
                <MediaPickerContainer value={es.featuredCover} handleOnChange={(value) => dispatch(setLocalizedFieldValue('featuredCover', 'es', value))} PickerComponent={MediaPicker} label="Featured Cover Español" />
              </Col>
              <Col md={6}>
                <MediaPickerContainer value={en.featuredCover} handleOnChange={(value) => dispatch(setLocalizedFieldValue('featuredCover', 'en', value))} PickerComponent={MediaPicker} label="Featured Cover English" />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="es-content">
                  <Form.Label>Featured Intro Español</Form.Label>
                  <RichEditor
                    editorState={es.featuredIntro}
                    onEditorStateChange={(editorState) => {
                      dispatch(setLocalizedFieldValue('featuredIntro', 'es', editorState))
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="en-content">
                  <Form.Label>Featured Intro English</Form.Label>
                  <RichEditor
                    editorState={en.featuredIntro}
                    onEditorStateChange={(editorState) => {
                      dispatch(setLocalizedFieldValue('featuredIntro', 'en', editorState))
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <MediaPickerContainer
                  clearable
                  value={es.splashBanner}
                  handleOnChange={(value) => dispatch(setLocalizedFieldValue('splashBanner', 'es', value))}
                  PickerComponent={MediaPicker}
                  label="Splash Banner Español"
                />
              </Col>
              <Col md={6}>
                <MediaPickerContainer
                  clearable
                  value={en.splashBanner}
                  handleOnChange={(value) => dispatch(setLocalizedFieldValue('splashBanner', 'en', value))}
                  PickerComponent={MediaPicker}
                  label="Splash Banner English"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="es-URL">
                  <Form.Label>Splash banner URL Spanish</Form.Label>
                  <Form.Control type="text" value={es.splashBannerUrl} onChange={(e) => dispatch(setLocalizedFieldValue('splashBannerUrl', 'es', e.target.value))} disabled={loading} />
                  <Form.Control.Feedback type="invalid">Please provide a valid URL.</Form.Control.Feedback>
                </Form.Group>

              </Col>

              <Col md={6}>
                <Form.Group controlId="en-URL">
                  <Form.Label>Splash banner URL English</Form.Label>
                  <Form.Control type="text" value={en.splashBannerUrl} onChange={(e) => dispatch(setLocalizedFieldValue('splashBannerUrl', 'en', e.target.value))} disabled={loading} />
                  <Form.Control.Feedback type="invalid">Please provide a valid URL.</Form.Control.Feedback>
                </Form.Group>
              </Col>

            </Row>

          </Col>

          <Col>
            <Form.Group controlId="actions">
              <Form.Label>Actions</Form.Label>
              <Row className="justify-content-between">
                <Col>
                  <Button variant="primary" type="submit" disabled={loading} block>
                    { loading ? 'Loading…' : 'Save' }
                  </Button>
                </Col>
              </Row>
            </Form.Group>

          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default SettingsClientForm
