import { chunk } from 'lodash'
import { useParams } from 'react-router-dom'

const paginationSettings = {
  perPage: 20,
}
// Paginate data and return current & total pages for list view
const usePagination = (data) => {
  const { page } = useParams()
  const { perPage } = paginationSettings
  let currentPage = 0
  let docs = data
  let totalPages = 1

  if (docs) {
    if (docs.length) {
      // We have data

      docs = data.slice(0)
      // clone read-only data array for manipulation

      const docsPages = chunk(docs, perPage)
      // divide data array into child arrays by perPage
      // see: https://lodash.com/docs/4.17.11#chunk

      if (page) {
        // "page" querystring present in url
        currentPage = +page - 1
        // set currentPage to querystring value
        // -1 for array index
      }

      docs = docsPages[currentPage]
      // get data chunk and reassign docs

      totalPages = docsPages.length
      // set total pages by length of chunk array
    }
  }

  return {
    docs,
    totalPages,
    currentPage,
  }
}

export default usePagination
