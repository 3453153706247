import {
  SETTINGS_FORM_SET_FIELD_VALUE,
  SETTINGS_FORM_SET_LOCALIZED_FIELD_VALUE,
  SETTINGS_FORM_SET_CHECKING_VALIDATION,
  SETTINGS_FORM_SET_ERROR,
  SETTINGS_FORM_RESET_ERROR,
  SETTINGS_FORM_SET_LOADING,
  SETTINGS_FORM_LOAD_DATA,
  SETTINGS_FORM_RESET,
} from './SettingsClientFormReducer'

export const setFieldValue = (field, data) => ({
  type: SETTINGS_FORM_SET_FIELD_VALUE,
  field,
  data,
})

export const setLocalizedFieldValue = (field, locale, data) => ({
  type: SETTINGS_FORM_SET_LOCALIZED_FIELD_VALUE,
  field,
  locale,
  data,
})

export const setCheckingValidation = () => ({
  type: SETTINGS_FORM_SET_CHECKING_VALIDATION,
})

export const setSettingsError = (error = null) => ({
  type: SETTINGS_FORM_SET_ERROR,
  data: error,
})

export const resetSettingsError = () => ({
  type: SETTINGS_FORM_RESET_ERROR,
})

export const setLoading = (loading) => ({
  type: SETTINGS_FORM_SET_LOADING,
  data: loading,
})

export const loadSettingsData = (data) => ({
  type: SETTINGS_FORM_LOAD_DATA,
  data,
})

export const resetSettingsForm = () => ({
  type: SETTINGS_FORM_RESET,
})
