import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'
import { createPagination } from '../lib/utils'

const Pagination = ({ totalPages, currentPage, list }) => (
  <Row className="justify-content-center">
    <Col xs="auto">
      {currentPage !== 0 ? (
        <Link
          to={{
            pathname: `/${list}`,
            search: `?page=${currentPage}`,
          }}
        >
          Previous
        </Link>
      ) : (
        <span>Previous</span>
      )}
    </Col>

    {createPagination(totalPages, currentPage, list)}

    <Col xs="auto">
      {currentPage < (totalPages - 1) ? (
        <Link
          to={{
            pathname: `/${list}`,
            search: `?page=${currentPage + 2}`,
          }}
        >
          Next
        </Link>
      ) : (
        <span>Next</span>
      )}
    </Col>
  </Row>
)

export default Pagination
