import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import CityForm from '../components/Cities/CityForm'

const CityContainer = ({ cityId, city }) => {
  if (!isLoaded(city)) {
    return <div>Loading...</div>
  }

  if (isEmpty(city)) {
    return '?'
  }

  return (
    <CityForm
      initialFormValues={city}
      cityId={cityId}
    />
  )
}

CityContainer.propTypes = {
  cityId: PropTypes.string.isRequired,
  city: PropTypes.object,
}

CityContainer.defaultProps = {
  city: {},
}

const firebaseQueries = (props) => {
  const { cityId } = props.match.params

  return [{
    collection: 'cities',
    doc: cityId,
    storeAs: `currentCity-${cityId}`,
  }]
}

const mapStateToProps = (state, props) => {
  // Here we are not mapping from state but form other props
  const { cityId } = props.match.params

  return {
    cityId,
    city: state.firestore.data[`currentCity-${cityId}`],
  }
}

export default compose(
  firestoreConnect(firebaseQueries),
  connect(mapStateToProps),
)(CityContainer)
