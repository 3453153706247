export const USER_FORM_SET_FIELD_VALUE = 'USER_FORM_SET_FIELD_VALUE'
export const USER_FORM_SET_LOCALIZED_FIELD_VALUE = 'USER_FORM_SET_LOCALIZED_FIELD_VALUE'
export const USER_FORM_SET_CHECKING_VALIDATION = 'USER_FORM_SET_CHECKING_VALIDATION'
export const USER_FORM_SET_ERROR = 'USER_FORM_SET_ERROR'
export const USER_FORM_RESET_ERROR = 'USER_FORM_RESET_ERROR'
export const USER_FORM_SET_LOADING = 'USER_FORM_SET_LOADING'
export const USER_FORM_RESET = 'USER_FORM_RESET'
export const USER_FORM_LOAD_DATA = 'USER_FORM_LOAD_DATA'

// This is our initial state
export const initialState = {
  formValues: {
    firstName: '',
    lastName: '',
    email: '',
    settings: {
      language: 'es',
      notifications: '',
      newsletter: '',
    },
    active: true,
    role: 'user',
    password: '',
  },
  isCheckingValidation: false,
  error: false,
  loading: false,
}

const UserFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_FORM_SET_FIELD_VALUE:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.field]: action.data,
        },
      }
    case USER_FORM_SET_CHECKING_VALIDATION:
      return {
        ...state,
        isCheckingValidation: true,
      }
    case USER_FORM_SET_ERROR: {
      return {
        ...state,
        error: action.data || false,
      }
    }
    case USER_FORM_RESET_ERROR: {
      return {
        ...state,
        error: false,
      }
    }
    case USER_FORM_SET_LOADING: {
      return {
        ...state,
        loading: action.data,
      }
    }
    case USER_FORM_RESET: {
      return initialState
    }
    default:
      return state
  }
}

export default UserFormReducer
