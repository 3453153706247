import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { logout as logoutAction } from '../actions/LoginActions'
import MainContent from '../components/MainContent'

const MainContainer = ({ auth, profile, logout }) => (
  <MainContent
    auth={auth}
    profile={profile}
    logout={logout}
  />
)

MainContainer.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
})

const mapDipatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutAction()),
})

export default compose(
  // withRouter is here to make MainContainer aware of location changes: https://reacttraining.com/react-router/web/guides/dealing-with-update-blocking
  withRouter,
  connect(mapStateToProps, mapDipatchToProps),
)(MainContainer)
