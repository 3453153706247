import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import HighlightList from '../components/Highlight/HighlightList'
import { listContainerPaginateProps } from '../lib/utils'

// In this case, we dont do isLoaded/isEmpty in the container
// we leverage that to <Events /> because theres things to show
// even while loading
const HighlightListContainer = ({
  highlighted, featured, totalPages, currentPage,
}) => (
  <HighlightList
    highlighted={highlighted}
    featured={featured}
    totalPages={totalPages}
    currentPage={currentPage}
  />
)

HighlightListContainer.propTypes = {
  highlighted: PropTypes.array,
  featured: PropTypes.array,
}

HighlightListContainer.defaultProps = {
  highlighted: [],
  featured: [],
}

const mapStateToProps = (state, props) => {
  const { docs, totalPages, currentPage } = listContainerPaginateProps(state, props, state.firestore.ordered['events-highlighted'])
  return {
    highlighted: docs,
    featured: state.firestore.ordered['events-featured'],
    totalPages,
    currentPage,
  }
}

export default compose(
  firestoreConnect([
    {
      collection: 'events',
      orderBy: ['createdDate', 'desc'],
      where: ['highlight', '==', 'highlighted'],
      storeAs: 'events-highlighted',
    },
    {
      collection: 'events',
      orderBy: ['featuredOrder', 'asc'],
      where: ['highlight', '==', 'featured'],
      storeAs: 'events-featured',
    },
  ]),
  connect(mapStateToProps),
)(HighlightListContainer)
