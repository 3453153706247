import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'

const UrlInput = ({
  editorState, active, value, setValue, onConfirm,
}) => (
  <>
    {active
        && (
        <div className="RichEditor-controls urlInputHolder">
          <InputGroup size="sm">
            <InputGroup.Prepend>
              <InputGroup.Text>URL</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
            />
            <InputGroup.Append>
              <Button onClick={onConfirm}>Confirm</Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
        )}
  </>
)

export default UrlInput
