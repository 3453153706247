/* eslint-disable no-underscore-dangle */
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { getFirebase } from 'react-redux-firebase'
import { createStoreWithFirebase } from './firebase'
import rootReducer from '../reducers'

const initialState = {}
const enhancers = []
const middleware = [thunk.withExtraArgument(getFirebase)] // add middlewares

// This is boilerplate code from the redux devtools extension
if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
)

const store = createStoreWithFirebase(
  rootReducer,
  initialState,
  composedEnhancers,
)

export default store
