/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import DeleteIcon from '@material-ui/icons/Delete'

import MediaLibraryModal from './MediaLibraryModal'

const MediaPicker = ({
  media, mediaPage, value, handleOnChange, totalPages, currentPage, label, clearable,
}) => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (media?.length && value) {
      const mediaMissing = media.filter((item) => item.id === value.id).length === 0
      if (mediaMissing) handleOnChange(null)
    }
  }, [handleOnChange, media, value])

  const handleClearClick = () => {
    handleOnChange(null)
  }

  return (
    <>
      <Row className="justify-content-between">
        {label
          && (
          <Col
            xs="12"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <label className="form-label">{label}</label>
            {
              value && clearable ? (
                <DeleteIcon
                  style={{
                    fontSize: 18,
                    cursor: 'pointer',
                  }}
                  onClick={handleClearClick}
                />
              ) : null
            }
          </Col>
          )}
        <Col xs="12" onClick={() => { setShowModal(true) }}>
          {!value ? (
            <div className="dropzone-container">Click here to select</div>
          ) : (
            <div className="form-group">
              <Image className="u-pointer" src={value.mediaUrl} thumbnail />
            </div>
          )}
        </Col>
      </Row>

      <MediaLibraryModal
        showModal={showModal}
        setShowModal={setShowModal}
        media={media}
        mediaPage={mediaPage}
        totalPages={totalPages}
        currentPage={currentPage}
        handleOnChange={handleOnChange}
      />
    </>
  )
}

MediaPicker.propTypes = {
  media: PropTypes.array,
  mediaPage: PropTypes.array,
}

MediaPicker.defaultProps = {
  media: [],
  mediaPage: [],
}

export default MediaPicker
