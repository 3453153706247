import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { transformEntryValues } from '../lib/utils'
import PageForm from '../components/Page/PageForm'

const PageContainer = ({ pageId, page }) => {
  if (!isLoaded(page)) {
    return <div>Loading...</div>
  }

  if (isEmpty(page)) {
    return '?'
  }

  // transform data from Firestore for Form components
  const pageTransformed = transformEntryValues(page)

  return (
    <PageForm
      initialFormValues={pageTransformed}
      pageId={pageId}
    />
  )
}

PageContainer.propTypes = {
  pageId: PropTypes.string.isRequired,
  page: PropTypes.object,
}

PageContainer.defaultProps = {
  page: {},
}

const firebaseQueries = (props) => {
  const { pageId } = props.match.params

  return [{
    collection: 'pages',
    doc: pageId,
    storeAs: `currentPage-${pageId}`,
  }]
}

const mapStateToProps = (state, props) => {
  // Here we are not mapping from state but form other props
  const { pageId } = props.match.params

  return {
    pageId,
    page: state.firestore.data[`currentPage-${pageId}`],
  }
}

export default compose(
  firestoreConnect(firebaseQueries),
  connect(mapStateToProps),
)(PageContainer)
