import {
  EVENT_FORM_SET_FIELD_VALUE,
  EVENT_FORM_SET_LOCALIZED_FIELD_VALUE,
  EVENT_FORM_SET_CHECKING_VALIDATION,
  EVENT_FORM_SET_ERROR,
  EVENT_FORM_RESET_ERROR,
  EVENT_FORM_SET_LOADING,
  EVENT_FORM_LOAD_DATA,
  EVENT_FORM_RESET,
} from './EventFormReducer'

export const setFieldValue = (field, data) => ({
  type: EVENT_FORM_SET_FIELD_VALUE,
  field,
  data,
})

export const setLocalizedFieldValue = (field, locale, data) => ({
  type: EVENT_FORM_SET_LOCALIZED_FIELD_VALUE,
  field,
  locale,
  data,
})

export const setCheckingValidation = () => ({
  type: EVENT_FORM_SET_CHECKING_VALIDATION,
})

export const setEventError = (error = null) => ({
  type: EVENT_FORM_SET_ERROR,
  data: error,
})

export const resetEventError = () => ({
  type: EVENT_FORM_RESET_ERROR,
})

export const setLoading = (loading) => ({
  type: EVENT_FORM_SET_LOADING,
  data: loading,
})

export const loadEventData = (data) => ({
  type: EVENT_FORM_LOAD_DATA,
  data,
})

export const resetEventForm = () => ({
  type: EVENT_FORM_RESET,
})
