import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import LoginForm from './LoginForm'

const Login = () => (
  <Container className="py-5">
    <Row className="justify-content-center">
      <Col xs={12} sm={12} md={8} xl={4}>
        <LoginForm />
      </Col>
    </Row>
  </Container>
)

export default Login
