import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Header from '../components/Header'

const HeaderContainer = ({ auth, profile }) => (
  <Header
    auth={auth}
    profile={profile}
  />
)

HeaderContainer.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
})
export default compose(
  connect(mapStateToProps),
)(HeaderContainer)
