import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { transformEntryValues } from '../lib/utils'
import ArticleForm from '../components/Article/ArticleForm'

const ArticleContainer = ({ articleId, article, related }) => {
  if (!isLoaded(article)) {
    return <div>Loading...</div>
  }

  if (isEmpty(article)) {
    return '?'
  }

  // transform data from Firestore for Form components
  const articleTransformed = transformEntryValues(article)

  return (
    <ArticleForm
      initialFormValues={articleTransformed}
      articleId={articleId}
      related={related}
    />
  )
}

ArticleContainer.propTypes = {
  articleId: PropTypes.string.isRequired,
  article: PropTypes.object,
  related: PropTypes.object,
}

ArticleContainer.defaultProps = {
  article: {},
  related: {},
}

const firebaseQueries = (props) => {
  const { articleId } = props.match.params

  return [
    {
      collection: 'articles',
      doc: articleId,
      storeAs: `currentArticle-${articleId}`,
    },
    {
      collection: 'events',
      where: ['relatedTo', 'array-contains', articleId],
      orderBy: ['createdDate', 'desc'],
      storeAs: `relatedTo-${articleId}-events`,
    },
    {
      collection: 'spaces',
      where: ['relatedTo', 'array-contains', articleId],
      orderBy: ['createdDate', 'desc'],
      storeAs: `relatedTo-${articleId}-spaces`,
    },
    {
      collection: 'articles',
      where: ['relatedTo', 'array-contains', articleId],
      orderBy: ['createdDate', 'desc'],
      storeAs: `relatedTo-${articleId}-articles`,
    },
  ]
}

const mapStateToProps = (state, props) => {
  // Here we are not mapping from state but form other props
  const { articleId } = props.match.params

  return {
    articleId,
    article: state.firestore.data[`currentArticle-${articleId}`],
    related: {
      spaces: state.firestore.ordered[`relatedTo-${articleId}-spaces`],
      events: state.firestore.ordered[`relatedTo-${articleId}-events`],
      articles: state.firestore.ordered[`relatedTo-${articleId}-articles`],
    },
  }
}

export default compose(
  firestoreConnect(firebaseQueries),
  connect(mapStateToProps),
)(ArticleContainer)
