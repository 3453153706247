import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { transformEntryValues } from '../lib/utils'
import EventForm from '../components/Event/EventForm'

const EventContainer = ({ eventId, event, related }) => {
  if (!isLoaded(event)) {
    return <div>Loading...</div>
  }

  if (isEmpty(event)) {
    return '?'
  }

  // transform data from Firestore for Form components
  const eventTransformed = transformEntryValues(event)

  return (
    <EventForm
      initialFormValues={eventTransformed}
      eventId={eventId}
      related={related}
    />
  )
}

EventContainer.propTypes = {
  eventId: PropTypes.string.isRequired,
  event: PropTypes.object,
}

EventContainer.defaultProps = {
  event: {},
}

const firebaseQueries = (props) => {
  const { eventId } = props.match.params

  return [
    {
      collection: 'events',
      doc: eventId,
      storeAs: `currentEvent-${eventId}`,
    },
    {
      collection: 'events',
      where: ['relatedTo', 'array-contains', eventId],
      orderBy: ['createdDate', 'desc'],
      storeAs: `relatedTo-${eventId}-events`,
    },
    {
      collection: 'spaces',
      where: ['relatedTo', 'array-contains', eventId],
      orderBy: ['createdDate', 'desc'],
      storeAs: `relatedTo-${eventId}-spaces`,
    },
    {
      collection: 'articles',
      where: ['relatedTo', 'array-contains', eventId],
      orderBy: ['createdDate', 'desc'],
      storeAs: `relatedTo-${eventId}-articles`,
    },
  ]
}

const mapStateToProps = (state, props) => {
  // Here we are not mapping from state but form other props
  const { eventId } = props.match.params
  const related = {
    spaces: state.firestore.ordered[`relatedTo-${eventId}-spaces`],
    events: state.firestore.ordered[`relatedTo-${eventId}-events`],
    articles: state.firestore.ordered[`relatedTo-${eventId}-articles`],
  }

  return {
    eventId,
    event: state.firestore.data[`currentEvent-${eventId}`],
    related,
  }
}

export default compose(
  firestoreConnect(firebaseQueries),
  connect(mapStateToProps),
)(EventContainer)
