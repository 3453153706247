/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import './RichEditor.css'

const LinkButton = ({
  editorState, urlInputActive, onToggle, active,
}) => {
  const handleOnToggle = (e) => {
    e.preventDefault()
    onToggle()
  }
  return (
    <div
      className={active || urlInputActive ? 'editor-style-button active' : 'editor-style-button'}
      onMouseDown={handleOnToggle}
    >
      Link
    </div>
  )
}

export default LinkButton
