import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.css'

const IgvDatePicker = (props) => (
  <DatePicker {...props} />
)

export default IgvDatePicker
