import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import './Header.css'
import LoggedUserNav from './Nav/LoggedUserNav'
import Logo from './Logo'

const Header = ({ auth, profile }) => (
  <header id="header" className="bg-light pt-1">
    <Container>
      <Row>
        <Col xs={4} md={2} className="text-left">
          <Nav>
            <Nav.Item>
              <Nav.Link as={Link} to="/">
                <Logo />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col>
          { auth.isLoaded
              && !auth.isEmpty
              && profile.isLoaded
              && profile.active !== false
              && (profile.role === 'editor'
              || profile.role === 'admin'
              || profile.role === 'manager'
              || profile.role === 'dev')
              && <LoggedUserNav profile={profile} />}
        </Col>
      </Row>
    </Container>
  </header>
)

export default Header
