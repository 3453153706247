import React from 'react'

const LocationMarker = () => (
  <div
    style={{
      position: 'absolute',
      transform: 'translate(-50%, -100%)',
      fontSize: '40px',
      color: '#dc3545',
    }}
  >
    ⬇
  </div>
)

export default LocationMarker
