import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import DatePicker from './DatePicker'

const HoursDay = ({ day, value, handleChange }) => (
  <Form.Group>
    <Row className="align-items-center">
      <Col xs={2}>
        <span>{day}</span>
      </Col>
      <Col xs={5}>
        <DatePicker
          selected={value[0]}
          onChange={(date) => handleChange(date, 0)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          dateFormat="h:mm aa"
          timeCaption="Time"
          className="form-control"
          isClearable
        />
      </Col>
      <Col xs={5}>
        <DatePicker
          selected={value[1]}
          onChange={(date) => handleChange(date, 1)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          dateFormat="h:mm aa"
          timeCaption="Time"
          className="form-control"
          isClearable
        />
      </Col>
    </Row>
  </Form.Group>
)

export default HoursDay
