import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'

const SettingsList = ({ settings }) => (
  <Container>
    <Row>
      <Col>
        <h2>Settings</h2>
      </Col>
    </Row>

    { !isLoaded(settings)
        && <div>Loading...</div>}

    { isLoaded(settings) && isEmpty(settings)
        && <div>Settings List Is Empty</div>}

    { isLoaded(settings) && !isEmpty(settings) && (
    <>
      <Table>
        <tbody>
          { settings.map((setting) => (
            <tr key={setting.id}>
              <td>
                <Link to={`/settings/${setting.id}`}>{setting.name}</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
    )}
  </Container>
)

SettingsList.propTypes = {
  settings: PropTypes.array,
}

SettingsList.defaultProps = {
  settings: [],
}

export default SettingsList
