import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { transformEntryValues } from '../lib/utils'
import UserForm from '../components/User/UserForm'

const UserContainer = ({ userId, user, auth }) => {
  if (!isLoaded(user)) {
    return <div>Loading...</div>
  }

  if (isEmpty(user)) {
    return '?'
  }

  // transform data from Firestore for Form components
  const userTransformed = transformEntryValues(user)

  return (
    <UserForm
      initialFormValues={userTransformed}
      userId={userId}
      authUID={auth.uid}
    />
  )
}

UserContainer.propTypes = {
  userId: PropTypes.string.isRequired,
  user: PropTypes.object,
}

UserContainer.defaultProps = {
  user: {},
}

const firebaseQueries = (props) => {
  const { userId } = props.match.params

  return [{
    collection: 'users',
    doc: userId,
    storeAs: `currentUser-${userId}`,
  }]
}

const mapStateToProps = (state, props) => {
  // Here we are not mapping from state but form other props
  const { userId } = props.match.params

  return {
    userId,
    user: state.firestore.data[`currentUser-${userId}`],
    auth: state.firebase.auth,
  }
}

export default compose(
  firestoreConnect(firebaseQueries),
  connect(mapStateToProps),
)(UserContainer)
