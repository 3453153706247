import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import './RichEditor.css'

import MediaLibraryModal from '../../Media/MediaLibraryModal'

const EditorMediaPicker = ({
  media,
  mediaPage,
  currentPage,
  totalPages,
  handleOnChange,
  modifier,
  editorState,
}) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Row style={{
        marginTop: '1rem',
      }}
      >
        <Col>
          <Button onClick={() => { setShowModal(true) }}>Insert Image</Button>
        </Col>
      </Row>

      <MediaLibraryModal
        showModal={showModal}
        setShowModal={setShowModal}
        media={media}
        mediaPage={mediaPage}
        totalPages={totalPages}
        currentPage={currentPage}
        handleOnChange={(mediaItem) => {
          const {
            id,
            width,
            height,
            updatedDate,
            mediaUrl,
            localizedContent,
          } = mediaItem
          handleOnChange(modifier(
            editorState,
            mediaUrl,
            {
              id,
              width,
              height,
              updatedDate,
              localizedContent,
            },
          ))
        }}
      />
    </>
  )
}

export default EditorMediaPicker
