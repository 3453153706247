import React from 'react'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import LogoutLink from '../Login/LogoutLink'

const LoggedUserNav = ({ profile }) => {
  const isAdminOrDev = (profile.role === 'admin' || profile.role === 'dev')
  return (
    <Nav>
      <Nav.Item>
        <Nav.Link as={Link} to="/events">Events</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/spaces">Spaces</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/articles">Articles</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/media-library">Media</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/highlights">Highlights</Nav.Link>
      </Nav.Item>
      {isAdminOrDev
        && (
        <>
          <Nav.Item>
            <Nav.Link as={Link} to="/users">Users</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/cities">Cities</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/settings">Settings</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/pages">Pages</Nav.Link>
          </Nav.Item>
        </>
        )}
      <Nav.Item>
        <LogoutLink Element={Nav.Link}>Logout</LogoutLink>
      </Nav.Item>
    </Nav>
  )
}

export default LoggedUserNav
