export const statusOptions = {
  draft: 'Draft',
  published: 'Published',
}
export const activeOptions = {
  active: 'Active',
  inactive: 'Inactive',
}

export const highlightOptions = {
  none: 'None',
  highlighted: 'Highlighted',
  featured: 'Featured',
}

export const articleTypeOptions = {
  article: 'Article',
  interview: 'Interview',
  essay: 'Essay',
  review: 'Review',
}

export const spaceTypeOptions = {
  gallery: 'Gallery',
  independent: 'Independent',
  museum: 'Museum',
  institution: 'Institution',
  residency: 'Residency',
  popup: 'Pop up',
  service: 'Service',
  studio: 'Studio',
}

export const spaceAppointmentOptions = {
  default: 'Default',
  option: 'Option',
  only: 'Only',
  and: 'And',
}

export const eventTypeOptions = {
  exhibition: 'Exhibition',
  performance: 'Performance',
  talk: 'Talk',
  guidedTour: 'Guided Tour',
  bookPresentation: 'Book Presentation',
  workshop: 'Workshop',
  screening: 'Screening',
  artFair: 'Art Fair',
  panel: 'Panel',
  closing: 'Closing',
  tour: 'Tour',
  event: 'Event',
  popup: 'Pop up',
  artSale: 'Art sale',
  onlineExhibition: 'Online exhibition',
}

export const cityOptions = {
  cdmx: 'CDMX',
  monterrey: 'Monterrey',
}

export const userRoleOptions = {
  user: 'User',
  editor: 'Editor',
  admin: 'Admin',
}

export const paginationSettings = {
  perPage: 20,
}

export const LocalFunctionsApi = 'http://localhost:5000/prod-ondamx-art/us-central1/'
export const CloudFunctionsApi = process.env.REACT_APP_FUNCTIONS_URL || process.env.NEXT_PUBLIC_FUNCTIONS_URL // 'https://us-central1-prod-ondamx-art.cloudfunctions.net/'
export const ElasticEndpoint = 'https://search-elastic-onda-mx-wtsuitsnilxmngwmodxxmzysci.us-east-2.es.amazonaws.com'
