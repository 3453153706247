import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { createModalPagination } from '../lib/utils'

const ModalPagination = ({ totalPages, currentPage, handlePageChange }) => (
  <Row className="justify-content-center">
    <Col xs="auto">
      {currentPage !== 0 ? (
        <button
          type="button"
          href="#"
          onClick={() => { handlePageChange(currentPage - 1) }}
        >
          Previous
        </button>
      ) : (
        <span>Previous</span>
      )}
    </Col>

    {createModalPagination(totalPages, currentPage, handlePageChange)}

    <Col xs="auto">
      {currentPage < (totalPages - 1) ? (
        <button
          type="button"
          href="#"
          onClick={() => { handlePageChange(currentPage + 1) }}
        >
          Next
        </button>
      ) : (
        <span>Next</span>
      )}
    </Col>
  </Row>
)

export default ModalPagination
