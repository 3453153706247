import React, { useReducer } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { convertToRaw } from 'draft-js'
import { getFirebase } from 'react-redux-firebase'
import { ParseEditorContent } from '../../lib/utils'
import { transformDatepickerValue } from '../../lib/datetime'
import RichEditor from '../Fields/RichEditor/RichEditor'
import Languages from '../Fields/Languages'
import MediaPicker from '../Media/MediaPicker'
import MediaPickerContainer from '../../containers/MediaPickerContainer'
import SettingsWebFormReducer, { initialState } from './SettingsWebFormReducer'
import {
  setFieldValue, setLocalizedFieldValue, setCheckingValidation, setLoading, resetSettingsError,
} from './SettingsWebFormActions'

const SettingsWebForm = ({ initialFormValues, settingsId }) => {
  // useReducer is a new react way to use a custom local reducer instead of the usual
  // local state. In this case, SettingsFormReducer is our reducer.
  const [formState, dispatch] = useReducer(
    SettingsWebFormReducer, // Our Reducer
    initialState,
    () => { // This third parameter overrites our initialstaate
      if (settingsId) {
        // NEED TO PARSE EDITOR CONTENT FROM INITIAL FORM VALUES
        return {
          ...initialState,
          formValues: {
            ...initialFormValues,
            localizedContent: {
              en: {
                ...initialFormValues.localizedContent.en,
                aboutContent: ParseEditorContent(
                  initialFormValues.localizedContent.en.aboutContent,
                ),
                aboutCredits: ParseEditorContent(
                  initialFormValues.localizedContent.en.aboutCredits,
                ),
                termsContent: ParseEditorContent(
                  initialFormValues.localizedContent.en.termsContent,
                ),
                privacyContent: ParseEditorContent(
                  initialFormValues.localizedContent.en.privacyContent,
                ),
              },
              es: {
                ...initialFormValues.localizedContent.es,
                aboutContent: ParseEditorContent(
                  initialFormValues.localizedContent.es.aboutContent,
                ),
                aboutCredits: ParseEditorContent(
                  initialFormValues.localizedContent.es.aboutCredits,
                ),
                termsContent: ParseEditorContent(
                  initialFormValues.localizedContent.es.termsContent,
                ),
                privacyContent: ParseEditorContent(
                  initialFormValues.localizedContent.es.privacyContent,
                ),
              },
            },
          },
        }
      }
      return initialState
    },
  )

  const {
    formValues,
    loading,
    isCheckingValidation,
  } = formState

  const {
    localizedContent,
    aboutImage,
    mockupImage,
    defaultOgImage,
    languages,
  } = formValues

  const submitSettingsForm = (data) => {
    const firebase = getFirebase()

    dispatch(resetSettingsError())
    dispatch(setLoading(true))

    const docRef = firebase.firestore().collection('settings').doc(settingsId)

    docRef.set({
      createdDate: new Date().getTime(),
      ...data,
      updatedDate: new Date().getTime(),
      // REFACTOR
      localizedContent: {
        es: {
          ...data.localizedContent.es,
          aboutContent: JSON.stringify(convertToRaw(
            data.localizedContent.es.aboutContent.getCurrentContent(),
          )),
          aboutCredits: JSON.stringify(convertToRaw(
            data.localizedContent.es.aboutCredits.getCurrentContent(),
          )),
          termsContent: JSON.stringify(convertToRaw(
            data.localizedContent.es.termsContent.getCurrentContent(),
          )),
          privacyContent: JSON.stringify(convertToRaw(
            data.localizedContent.es.privacyContent.getCurrentContent(),
          )),
        },
        en: {
          ...data.localizedContent.en,
          aboutContent: JSON.stringify(convertToRaw(
            data.localizedContent.en.aboutContent.getCurrentContent(),
          )),
          aboutCredits: JSON.stringify(convertToRaw(
            data.localizedContent.en.aboutCredits.getCurrentContent(),
          )),
          termsContent: JSON.stringify(convertToRaw(
            data.localizedContent.en.termsContent.getCurrentContent(),
          )),
          privacyContent: JSON.stringify(convertToRaw(
            data.localizedContent.en.privacyContent.getCurrentContent(),
          )),
        },
      },
      // convert date values to explicit CDMX timestamps
      publishDate: transformDatepickerValue(data.publishDate),
      // Convert editorState to Raw content
    })
      .then(() => {
        dispatch(setLoading(false))
        // this is where we can trigger a notification or something
      })
      .catch((error) => {
        // Error ocurred
        dispatch(setLoading(false))
        // dispatch(setSettingsError(error))
      })
  }

  const onSubmitForm = (event) => {
    const form = event.currentTarget

    event.preventDefault()
    event.stopPropagation()

    if (form.checkValidity() === true) {
      submitSettingsForm(formValues)
    }

    // start checking validation on the form now we have initial values
    dispatch(setCheckingValidation())
  }

  const { en, es } = localizedContent

  return (
    <Container>
      <h2>Web Settings</h2>

      <Form
        noValidate
        validated={isCheckingValidation}
        onSubmit={(event) => onSubmitForm(event)}
      >
        <Row>
          <Col md={8}>

            <Row>
              <Col md={6}>
                <Form.Group controlId="es-about-headline">
                  <Form.Label>About Headline Español</Form.Label>
                  <Form.Control type="text" value={es.aboutHeadline} onChange={(e) => dispatch(setLocalizedFieldValue('aboutHeadline', 'es', e.target.value))} disabled={loading} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="en-about-headline">
                  <Form.Label>About Headline English</Form.Label>
                  <Form.Control type="text" value={en.aboutHeadline} onChange={(e) => dispatch(setLocalizedFieldValue('aboutHeadline', 'en', e.target.value))} disabled={loading} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="es-about-content">
                  <Form.Label>About Content Español</Form.Label>
                  <RichEditor
                    editorState={es.aboutContent}
                    onEditorStateChange={(editorState) => {
                      dispatch(setLocalizedFieldValue('aboutContent', 'es', editorState))
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="en-about-content">
                  <Form.Label>About Content English</Form.Label>
                  <RichEditor
                    editorState={en.aboutContent}
                    onEditorStateChange={(editorState) => {
                      dispatch(setLocalizedFieldValue('aboutContent', 'en', editorState))
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="es-about-credits">
                  <Form.Label>About Credits Español</Form.Label>
                  <RichEditor
                    editorState={es.aboutCredits}
                    onEditorStateChange={(editorState) => {
                      dispatch(setLocalizedFieldValue('aboutCredits', 'es', editorState))
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="en-about-credits">
                  <Form.Label>About Credits English</Form.Label>
                  <RichEditor
                    editorState={en.aboutCredits}
                    onEditorStateChange={(editorState) => {
                      dispatch(setLocalizedFieldValue('aboutCredits', 'en', editorState))
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <MediaPickerContainer value={es.downloadAdMobile} handleOnChange={(value) => dispatch(setLocalizedFieldValue('downloadAdMobile', 'es', value))} PickerComponent={MediaPicker} label="Download Ad Mobile Español" />
              </Col>
              <Col md={6}>
                <MediaPickerContainer value={en.downloadAdMobile} handleOnChange={(value) => dispatch(setLocalizedFieldValue('downloadAdMobile', 'en', value))} PickerComponent={MediaPicker} label="Download Ad Mobile English" />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <MediaPickerContainer value={es.downloadAdDesktop} handleOnChange={(value) => dispatch(setLocalizedFieldValue('downloadAdDesktop', 'es', value))} PickerComponent={MediaPicker} label="Download Ad Desktop Español" />
              </Col>
              <Col md={6}>
                <MediaPickerContainer value={en.downloadAdDesktop} handleOnChange={(value) => dispatch(setLocalizedFieldValue('downloadAdDesktop', 'en', value))} PickerComponent={MediaPicker} label="Download Ad Desktop English" />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="es-terms-content">
                  <Form.Label>Terms Content Español</Form.Label>
                  <RichEditor
                    editorState={es.termsContent}
                    onEditorStateChange={(editorState) => {
                      dispatch(setLocalizedFieldValue('termsContent', 'es', editorState))
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="en-terms-content">
                  <Form.Label>Terms Content English</Form.Label>
                  <RichEditor
                    editorState={en.termsContent}
                    onEditorStateChange={(editorState) => {
                      dispatch(setLocalizedFieldValue('termsContent', 'en', editorState))
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="es-terms-content">
                  <Form.Label>Privacy Content Español</Form.Label>
                  <RichEditor
                    editorState={es.privacyContent}
                    onEditorStateChange={(editorState) => {
                      dispatch(setLocalizedFieldValue('privacyContent', 'es', editorState))
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="en-terms-content">
                  <Form.Label>Privacy Content English</Form.Label>
                  <RichEditor
                    editorState={en.privacyContent}
                    onEditorStateChange={(editorState) => {
                      dispatch(setLocalizedFieldValue('privacyContent', 'en', editorState))
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>

          <Col md={4}>
            <Form.Group controlId="actions">
              <Form.Label>Actions</Form.Label>
              <Row className="justify-content-between">
                <Col>
                  <Button variant="primary" type="submit" disabled={loading} block>
                    { loading ? 'Loading…' : 'Save' }
                  </Button>
                </Col>
              </Row>
            </Form.Group>
            <Languages value={languages} onChange={(value) => dispatch(setFieldValue('languages', value))} />
            <MediaPickerContainer value={aboutImage} handleOnChange={(value) => dispatch(setFieldValue('aboutImage', value))} PickerComponent={MediaPicker} label="About Image" />
            <MediaPickerContainer value={mockupImage} handleOnChange={(value) => dispatch(setFieldValue('mockupImage', value))} PickerComponent={MediaPicker} label="Mockup Image" />
            <MediaPickerContainer value={defaultOgImage} handleOnChange={(value) => dispatch(setFieldValue('defaultOgImage', value))} PickerComponent={MediaPicker} label="Default SEO Image" />
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default SettingsWebForm
