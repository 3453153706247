import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { connect } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
import ModalPagination from '../ModalPagination'
import setModalPageAction from '../../actions/PaginationActions'
import { getMediaThumb } from '../../lib/images'

const MediaLibraryModal = ({
  showModal,
  setShowModal,
  media,
  mediaPage,
  totalPages,
  currentPage,
  handleOnChange,
  setModalPage,
}) => (
  <Modal
    show={showModal}
    onHide={() => {
      setShowModal(false)
      setModalPage(0)
    }}
    dialogClassName="media-modal"
  >
    <Modal.Header closeButton>
      <Modal.Title>
        Select Media
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      { !isLoaded(media)
          && (
          <Row className="justify-content-between">
            <Col>
              <div>Loading...</div>
            </Col>
          </Row>
          )}

      { isLoaded(media) && isEmpty(mediaPage)
          && (
          <Row className="justify-content-between">
            <Col>
              <div>Media Library Is Empty</div>
            </Col>
          </Row>
          )}

      { isLoaded(media) && !isEmpty(mediaPage) && (
      <>
        <Row>
          { mediaPage.map((mediaItem) => {
            const thumbSrc = getMediaThumb(mediaItem.mediaUrl)
            return (
              <Col
                md={4}
                xl={3}
                key={mediaItem.id}
                onClick={() => {
                  handleOnChange(mediaItem)
                  setShowModal(false)
                }}
                style={{
                  marginBottom: '1rem',
                }}
              >
                { thumbSrc
                      && <Image className="u-pointer" src={thumbSrc} thumbnail />}
              </Col>
            )
          })}
        </Row>
        <ModalPagination
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={setModalPage}
        />
      </>
      )}
    </Modal.Body>
  </Modal>
)

MediaLibraryModal.propTypes = {
  media: PropTypes.array,
}

MediaLibraryModal.defaultProps = {
  media: [],
}

const mapDispatchToProps = (dispatch) => ({
  setModalPage: (page) => dispatch(setModalPageAction(page)),
})

export default compose(
  connect(null, mapDispatchToProps),
)(MediaLibraryModal)
