import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Pagination from '../Pagination'

const UserList = ({
  users, totalPages, currentPage,
}) => (
  <Container>
    <Row>
      <Col>
        <h2>Users</h2>
      </Col>
      <Col xs="auto">
        <Link to="/user/new">
          <Button>Add New</Button>
        </Link>
      </Col>
    </Row>

    { !isLoaded(users)
        && <div>Loading...</div>}

    { isLoaded(users) && isEmpty(users)
        && <div>Users List Is Empty</div>}

    { isLoaded(users) && !isEmpty(users) && (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              Email
            </th>
            <th>
              Name
            </th>
            <th>
              Role
            </th>
            <th>
              Status
            </th>
            <th>
              Created
            </th>
          </tr>
        </thead>
        <tbody>
          { users.map((user) => (
            <tr key={user.id}>
              <td>
                <Link to={`/user/edit/${user.id}`}>{user.email}</Link>
              </td>
              <td>
                {`${user.firstName} ${user.lastName}`}
              </td>
              <td>
                {user.role}
              </td>
              <td>
                {user.status}
              </td>
              <td>
                {new Date(user.createdDate).toLocaleString('default', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination totalPages={totalPages} currentPage={currentPage} list="users" />
    </>
    )}
  </Container>
)

UserList.propTypes = {
  users: PropTypes.array,
}

UserList.defaultProps = {
  users: [],
}

export default UserList
