import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Pagination from '../Pagination'

const ArticleList = ({
  articles, totalPages, currentPage,
}) => (
  <Container>
    <Row>
      <Col>
        <h2>Articles</h2>
      </Col>
      <Col xs="auto">
        <Link to="/article/new">
          <Button>Add New</Button>
        </Link>
      </Col>
    </Row>

    { !isLoaded(articles)
        && <div>Loading...</div>}

    { isLoaded(articles) && isEmpty(articles)
        && <div>Articles List Is Empty</div>}

    { isLoaded(articles) && !isEmpty(articles) && (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              Title
            </th>
            <th>
              Author
            </th>
            <th>
              Type
            </th>
            <th>
              Published
            </th>
          </tr>
        </thead>
        <tbody>
          { articles.map((article) => (
            <tr key={article.id}>
              <td>
                <Link to={`/article/edit/${article.id}`}>{article.localizedContent.es.title}</Link>
              </td>
              <td>
                <span>{article.author}</span>
              </td>
              <td>
                <span>{article.type}</span>
              </td>
              <td>
                {article.publishDate
                        && (
                        <span>
                          {new Date(article.publishDate.seconds * 1000).toLocaleString('default', {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                          })}
                        </span>
                        )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination totalPages={totalPages} currentPage={currentPage} list="articles" />
    </>
    )}
  </Container>
)

ArticleList.propTypes = {
  articles: PropTypes.array,
}

ArticleList.defaultProps = {
  articles: [],
}

export default ArticleList
