import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'

function Input({
  id,
  label,
  errors,
  register: { ref, ...otherRegisterProps },
  ...otherProps
}) {
  return (
    <Form.Group controlId={id}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        ref={ref}
        {...otherRegisterProps}
        {...otherProps}
      />
      {
        errors ? (
          <Form.Text id={`${id}-helpBlock`} className="text-danger">
            {errors}
          </Form.Text>
        ) : null
      }
    </Form.Group>
  )
}

Input.propTypes = {
  register: PropTypes.object,
}

Input.defaultProps = {
  register: {},
}

export default Input
