import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import PageList from '../components/Page/PageList'
import { listContainerPaginateProps } from '../lib/utils'

// In this case, we dont do isLoaded/isEmpty in the container
// we leverage that to <Pages /> because theres things to show
// even while loading
const PageListContainer = ({ pages, totalPages, currentPage }) => (
  <PageList
    pages={pages}
    totalPages={totalPages}
    currentPage={currentPage}
  />
)

PageListContainer.propTypes = {
  pages: PropTypes.array,
}

PageListContainer.defaultProps = {
  pages: [],
}

const mapStateToProps = (state, props) => {
  const { docs, totalPages, currentPage } = listContainerPaginateProps(
    state, props, state.firestore.ordered.pages,
  )
  return {
    pages: docs,
    totalPages,
    currentPage,
  }
}

export default compose(
  firestoreConnect([{
    collection: 'pages',
    orderBy: ['order', 'asc'],
  }]),
  connect(mapStateToProps),
)(PageListContainer)
