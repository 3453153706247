import {
  PAGE_FORM_SET_FIELD_VALUE,
  PAGE_FORM_SET_LOCALIZED_FIELD_VALUE,
  PAGE_FORM_SET_CHECKING_VALIDATION,
  PAGE_FORM_SET_ERROR,
  PAGE_FORM_RESET_ERROR,
  PAGE_FORM_SET_LOADING,
  PAGE_FORM_LOAD_DATA,
  PAGE_FORM_RESET,
} from './PageFormReducer'

export const setFieldValue = (field, data) => ({
  type: PAGE_FORM_SET_FIELD_VALUE,
  field,
  data,
})

export const setLocalizedFieldValue = (field, locale, data) => ({
  type: PAGE_FORM_SET_LOCALIZED_FIELD_VALUE,
  field,
  locale,
  data,
})

export const setCheckingValidation = () => ({
  type: PAGE_FORM_SET_CHECKING_VALIDATION,
})

export const setPageError = (error = null) => ({
  type: PAGE_FORM_SET_ERROR,
  data: error,
})

export const resetPageError = () => ({
  type: PAGE_FORM_RESET_ERROR,
})

export const setLoading = (loading) => ({
  type: PAGE_FORM_SET_LOADING,
  data: loading,
})

export const loadPageData = (data) => ({
  type: PAGE_FORM_LOAD_DATA,
  data,
})

export const resetPageForm = () => ({
  type: PAGE_FORM_RESET,
})
