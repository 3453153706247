import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import SettingsList from '../components/Settings/SettingsList'

// In this case, we dont do isLoaded/isEmpty in the container
// we leverage that to <Settingss /> because theres things to show
// even while loading
const SettingsListContainer = ({ settings }) => (
  <SettingsList
    settings={settings}
  />
)

SettingsListContainer.propTypes = {
  settings: PropTypes.array,
}

SettingsListContainer.defaultProps = {
  settings: [],
}

const mapStateToProps = (state, props) => ({
  settings: state.firestore.ordered.settings,
})

export default compose(
  firestoreConnect([{
    collection: 'settings',
  }]),
  connect(mapStateToProps),
)(SettingsListContainer)
