import { generatePerLocale } from '../../lib/locales'
import { ParseEditorContent, emptyEditorState } from '../../lib/utils'

export const EVENT_FORM_SET_FIELD_VALUE = 'EVENT_FORM_SET_FIELD_VALUE'
export const EVENT_FORM_SET_LOCALIZED_FIELD_VALUE = 'EVENT_FORM_SET_LOCALIZED_FIELD_VALUE'
export const EVENT_FORM_SET_CHECKING_VALIDATION = 'EVENT_FORM_SET_CHECKING_VALIDATION'
export const EVENT_FORM_SET_ERROR = 'EVENT_FORM_SET_ERROR'
export const EVENT_FORM_RESET_ERROR = 'EVENT_FORM_RESET_ERROR'
export const EVENT_FORM_SET_LOADING = 'EVENT_FORM_SET_LOADING'
export const EVENT_FORM_RESET = 'EVENT_FORM_RESET'
export const EVENT_FORM_LOAD_DATA = 'EVENT_FORM_LOAD_DATA'

// Post initial state
const postContentInitialState = {
  name: '',
  title: '',
  mainContent: ParseEditorContent(emptyEditorState),
  seoTitle: '',
  seoDescription: '',
  featuredSummary: '',
}

// This is our initial state
export const initialState = {
  formValues: {
    localizedContent: generatePerLocale(postContentInitialState),
    imageCarousel: [],
    space: {
      name: '',
      id: '',
    },
    city: 'CDMX',
    coverImage: {},
    location: {
      address: {
        street: '',
        number: '',
        extra: '',
        neighborhood: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
      },
      lat: 0,
      lon: 0,
    },
    openingStart: null,
    openingEnd: null,
    hasOpening: false,
    closing: null,
    hours: {
      monday: [null, null],
      tuesday: [null, null],
      wednesday: [null, null],
      thursday: [null, null],
      friday: [null, null],
      saturday: [null, null],
      sunday: [null, null],
    },
    appointment: 'default',
    ticketUrl: '',
    status: 'draft',
    highlight: 'none',
    featuredOrder: 1,
    type: 'exhibition',
    artists: [],
    keywords: [],
    relatedTo: [],
    relatedExternal: [],
    multiDay: false,
    languages: {
      en: true,
      es: true,
    },
    bookmarkCount: 0,
    bookmarks: [],
    slug: '',
  },
  isCheckingValidation: false,
  error: false,
  loading: false,
}

const localizedContentReducer = (state = generatePerLocale(postContentInitialState), action) => {
  switch (action.type) {
    case EVENT_FORM_SET_LOCALIZED_FIELD_VALUE:
      return {
        ...state,
        [action.locale]: {
          ...state[action.locale],
          [action.field]: action.data,
        },
      }
    default:
      return state
  }
}

const EventFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENT_FORM_SET_FIELD_VALUE:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.field]: action.data,
        },
      }
    case EVENT_FORM_SET_LOCALIZED_FIELD_VALUE:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          localizedContent: localizedContentReducer(state.formValues.localizedContent, action),
        },
      }
    case EVENT_FORM_SET_CHECKING_VALIDATION:
      return {
        ...state,
        isCheckingValidation: true,
      }
    case EVENT_FORM_SET_ERROR: {
      return {
        ...state,
        error: action.data || false,
      }
    }
    case EVENT_FORM_RESET_ERROR: {
      return {
        ...state,
        error: false,
      }
    }
    case EVENT_FORM_SET_LOADING: {
      return {
        ...state,
        loading: action.data,
      }
    }
    case EVENT_FORM_RESET: {
      return initialState
    }
    default:
      return state
  }
}

export default EventFormReducer
