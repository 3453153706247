import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Pagination from '../Pagination'

const PageList = ({
  cities, totalPages, currentPage,
}) => (
  <Container>
    <Row>
      <Col>
        <h2>Cities</h2>
      </Col>
      <Col xs="auto">
        <Link to="/cities/new">
          <Button>Add New</Button>
        </Link>
      </Col>
    </Row>

    { !isLoaded(cities)
        && <div>Loading...</div>}

    { isLoaded(cities) && isEmpty(cities)
        && <div>Cities List Is Empty</div>}

    { isLoaded(cities) && !isEmpty(cities) && (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              Name
            </th>
            <th>
              Active
            </th>
            <th>
              Code
            </th>
          </tr>
        </thead>
        <tbody>
          { cities.map((city) => (
            <tr key={city.id}>
              <td>
                <Link to={`/cities/edit/${city.id}`}>{city.localizedContent.es.name}</Link>
              </td>
              <td>
                {city.status}
              </td>
              <td>
                {city.id}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination totalPages={totalPages} currentPage={currentPage} list="cities" />
    </>
    )}
  </Container>
)

PageList.propTypes = {
  cities: PropTypes.array,
}

PageList.defaultProps = {
  cities: [],
}

export default PageList
