import {
  USER_FORM_SET_FIELD_VALUE,
  USER_FORM_SET_LOCALIZED_FIELD_VALUE,
  USER_FORM_SET_CHECKING_VALIDATION,
  USER_FORM_SET_ERROR,
  USER_FORM_RESET_ERROR,
  USER_FORM_SET_LOADING,
  USER_FORM_LOAD_DATA,
  USER_FORM_RESET,
} from './UserFormReducer'

export const setFieldValue = (field, data) => ({
  type: USER_FORM_SET_FIELD_VALUE,
  field,
  data,
})

export const setLocalizedFieldValue = (field, locale, data) => ({
  type: USER_FORM_SET_LOCALIZED_FIELD_VALUE,
  field,
  locale,
  data,
})

export const setCheckingValidation = () => ({
  type: USER_FORM_SET_CHECKING_VALIDATION,
})

export const setUserError = (error = null) => ({
  type: USER_FORM_SET_ERROR,
  data: error,
})

export const resetUserError = () => ({
  type: USER_FORM_RESET_ERROR,
})

export const setLoading = (loading) => ({
  type: USER_FORM_SET_LOADING,
  data: loading,
})

export const loadUserData = (data) => ({
  type: USER_FORM_LOAD_DATA,
  data,
})

export const resetUserForm = () => ({
  type: USER_FORM_RESET,
})
