import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import {
  setFieldValue as setFieldValueAction,
  submitLoginForm as submitLoginFormAction,
  setCheckingValidation as setCheckingValidationAction,
} from '../../actions/LoginActions'

const LoginForm = ({
  formState, setFieldValue, submitLoginForm, setCheckingValidation,
}) => {
  const {
    formValues, isCheckingValidation, error, loading,
  } = formState
  const { email, password } = formValues

  return (
    <Form
      noValidate
      validated={isCheckingValidation}
      onSubmit={(event) => {
        const form = event.currentTarget

        event.preventDefault()
        event.stopPropagation()

        if (form.checkValidity() === true) {
          submitLoginForm()
        }

        // start checking validation on the form now we have initial values
        setCheckingValidation()
      }}
    >
      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control required type="email" placeholder="Enter email" value={email} onChange={(e) => setFieldValue('email', e.target.value)} disabled={loading} autoComplete="username" />
        <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control required type="password" placeholder="Password" value={password} onChange={(e) => setFieldValue('password', e.target.value)} disabled={loading} autoComplete="current-password" />
        <Form.Control.Feedback type="invalid">Please provide a password.</Form.Control.Feedback>
      </Form.Group>

      { error
          && (
          <Alert variant="danger">
            <span>{error.message}</span>
          </Alert>
          )}

      <Button variant="primary" type="submit" disabled={loading}>
        { loading ? 'Loading…' : 'Submit' }
      </Button>

    </Form>
  )
}

LoginForm.propTypes = {
  formState: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitLoginForm: PropTypes.func.isRequired,
  setCheckingValidation: PropTypes.func.isRequired,
}

const mapStateToProps = ({ loginForm }) => ({
  formState: loginForm,
})

const mapDipatchToProps = (dispatch) => ({
  setFieldValue: (field, value) => dispatch(setFieldValueAction(field, value)),
  setCheckingValidation: () => dispatch(setCheckingValidationAction()),
  submitLoginForm: (email, password) => dispatch(submitLoginFormAction(email, password)),
})

export default compose(
  connect(
    mapStateToProps,
    mapDipatchToProps,
  ),
)(LoginForm)
