import React from 'react'
import PropTypes from 'prop-types'

const Logo = ({
  color,
  width,
  height,
}) => (
  <svg
    color={color}
    height={height}
    width={width}
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 1024 284.3"
  >
    <g>
      <path
        fill={color}
        d="M324.5,0h-27.7c-4.7,0-7.4,2.5-7.4,7.4v79.8c0,2.5-2,3-3,0.8L252.9,5.7c-1.7-4-4.4-5.7-8.6-5.7h-36.3
  c-4.6,0-7.4,2.5-7.4,7.4v158.1c0,4.7,2.8,7.4,7.4,7.4h27.7c5,0,7.4-2.8,7.4-7.4V85.7c0-2.5,2-3,3-0.7l33.6,82.3
  c1.7,4,4.4,5.7,8.6,5.7h36.3c4.9,0,7.4-2.8,7.4-7.4V7.4C332,2.5,329.5,0,324.5,0z"
      />
      <path
        fill={color}
        d="M494.6,0h-55.1c-4.9,0.2-7.4,2.7-7.4,7.7v209.7c0,4.7,2.7,7.4,7.4,7.4h55.1c46.5,0,61.8-19,61.8-59.5v-106
  C556.4,18.8,541.1,0,494.6,0z M513.1,165.6c0,14.8-5.9,16.8-17.8,16.8H482c-4.7,0-7.4-2.8-7.4-7.4v-125c0-4.9,2.7-7.4,7.4-7.4h13.3
  c11.9,0,17.8,1.5,17.8,16.8C513.1,59.5,513.1,165.3,513.1,165.6z"
      />
      <path
        fill={color}
        d="M713.3,0c-55.6,0-60.5,33.1-60.5,47v211.7c0,22.6,2.7,25.6,7.4,25.6h27.7c5,0,7.4-2.5,7.4-7.4v-38.6
  c0-4.9,2.7-7.4,7.4-7.4H724c4.9,0,7.4,2.5,7.4,7.4v38.6c0,5,2.7,7.4,7.4,7.4h27.7c4.9,0,7.4-3,7.4-25.6V47
  C773.9,33.1,768.9,0,713.3,0z M731.4,180.7c0,4.9-2.5,7.4-7.4,7.6h-21.2c-4.7,0-7.4-2.7-7.4-7.4V64.2c0-11.9,1.7-17.8,18-17.8
  c16.3,0,18,5.9,18,17.8V180.7z"
      />
      <path
        fill={color}
        d="M934.8,0h-18.9c-1.6,0-2.5,0.9-2.9,2.5l-6.5,26.6c-0.2,0.9-0.9,0.9-1.1,0L899,2.5C898.6,0.9,897.7,0,896,0
  h-18.9c-1.8,0-2.7,0.9-2.7,2.8v58.4c0,1.7,0.9,2.7,2.7,2.7h10.2c1.8,0,2.7-1,2.7-2.7v-36c0-0.6,0.8-0.7,1-0.1l8.8,36.4
  c0.4,1.6,1.4,2.4,3,2.4h6c1.7,0,2.6-0.8,3.1-2.4l8.8-36.4c0.2-0.7,1-0.6,1,0.1v36c0,1.7,1,2.7,2.7,2.7h10.2c1.9,0,2.8-1,2.8-2.7
  V2.8C937.6,0.9,936.7,0,934.8,0z"
      />
      <path
        fill={color}
        d="M1023.5,60.6l-17.3-29.2c-0.2-0.4-0.2-0.8,0-1.3l15.3-26.8c1.1-1.9,0.3-3.3-1.8-3.3h-11.4
  c-1.5,0-2.5,0.6-3.3,1.8l-7.5,12.7c-0.4,0.6-0.9,0.6-1.3,0l-7.4-12.7c-0.7-1.3-1.7-1.8-3.3-1.8h-11.3c-2.2,0-3,1.4-1.9,3.3
  l15.5,26.4c0.3,0.4,0.3,0.8,0,1.3l-17.6,29.6c-1.1,1.9-0.3,3.3,1.9,3.3h12.5c1.5,0,2.5-0.6,3.3-1.9l8.8-15.3c0.3-0.6,0.8-0.6,1.2,0
  l9,15.3c0.8,1.3,1.8,1.8,3.3,1.8h11.3C1023.8,63.9,1024.6,62.5,1023.5,60.6z"
      />
      <path
        fill={color}
        d="M53.6,0C24,0,0,24,0,53.6c0,29.6,24,53.6,53.6,53.6s53.6-24,53.6-53.6C107.2,24,83.2,0,53.6,0z M53.6,73.8
  c-11.2,0-20.2-9-20.2-20.2s9-20.2,20.2-20.2c11.2,0,20.2,9,20.2,20.2S64.7,73.8,53.6,73.8z"
      />
    </g>
  </svg>

)

Logo.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

Logo.defaultProps = {
  color: '#0505FE',
  width: 100,
  height: 60,
}

export default Logo
