import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Login from './Login/Login'
import ArticleForm from './Article/ArticleForm'
import ArticleContainer from '../containers/ArticleContainer'
import ArticleListContainer from '../containers/ArticleListContainer'
import SpaceForm from './Space/SpaceForm'
import SpaceContainer from '../containers/SpaceContainer'
import SpaceListContainer from '../containers/SpaceListContainer'
import EventForm from './Event/EventForm'
import EventContainer from '../containers/EventContainer'
import EventListContainer from '../containers/EventListContainer'
import UserForm from './User/UserForm'
import UserContainer from '../containers/UserContainer'
import UserListContainer from '../containers/UserListContainer'
import PageForm from './Page/PageForm'
import PageContainer from '../containers/PageContainer'
import PageListContainer from '../containers/PageListContainer'
import HighlightListContainer from '../containers/HighlightListContainer'
import MediaLibraryContainer from '../containers/MediaLibraryContainer'
import SettingsContainer from '../containers/SettingsContainer'
import SettingsListContainer from '../containers/SettingsListContainer'
import CityContainer from '../containers/CityContainer'
import CityListContainer from '../containers/CityListContainer'
import CityForm from './Cities/CityForm'

const MainContent = ({
  auth, profile, navigation, logout,
}) => {
  if (profile.isLoaded && (profile.role === 'user' || profile.active === false)) {
    logout()
  }

  if (auth.isLoaded && auth.isEmpty) {
    return (
      <main>
        <Login />
      </main>
    )
  }

  if (auth.isLoaded && !auth.isEmpty && profile.isLoaded) {
    const isAdminOrDev = (profile.role === 'admin' || profile.role === 'dev')
    return (
      <main>
        <Switch>
          <Route exact path="/articles" component={ArticleListContainer} />
          <Route exact path="/article/new" component={ArticleForm} />
          <Route exact path="/article/edit/:articleId" component={ArticleContainer} />
          <Route exact path="/spaces" component={SpaceListContainer} />
          <Route exact path="/space/new" component={SpaceForm} />
          <Route exact path="/space/edit/:spaceId" component={SpaceContainer} />
          <Route exact path="/events" component={EventListContainer} />
          <Route exact path="/event/new" component={EventForm} />
          <Route exact path="/event/edit/:eventId" component={EventContainer} />
          {isAdminOrDev
            && <Route exact path="/users" component={UserListContainer} />}
          {isAdminOrDev
            && <Route exact path="/user/new" component={UserForm} />}
          {isAdminOrDev
            && <Route exact path="/user/edit/:userId" component={UserContainer} />}
          <Route exact path="/pages" component={PageListContainer} />
          <Route exact path="/page/new" component={PageForm} />
          <Route exact path="/page/edit/:pageId" component={PageContainer} />
          <Route exact path="/media-library" component={MediaLibraryContainer} />
          <Route exact path="/highlights" component={HighlightListContainer} />
          <Route exact path="/cities" component={CityListContainer} />
          <Route exact path="/cities/new" component={CityForm} />
          <Route exact path="/cities/edit/:cityId" component={CityContainer} />
          <Route exact path="/settings" component={SettingsListContainer} />
          <Route exact path="/settings/:settingsId" component={SettingsContainer} />
        </Switch>
      </main>
    )
  }

  return null
}

MainContent.propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
}

export default MainContent
